import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from "../libs/contextLib";
import './CustomFooter.scss'

export default function CustomFooter(){
	const { language } = useAppContext();
	const history = useHistory();

	return (
		<div className="CustomFooter">
			<a className="footer-link" href="/contact">
				{language === "en" ?  "Contact Us" : "联系我们"}
			</a>
			<br/>
			<a className="footer-link" href="/terms-and-conditions">
				{language === "en" ?  "Terms & Conditions" : "条款和条件"}
			</a>
			<br/>
			<a className="footer-link" href="/privacy-policy">
				{language === "en" ?  "Privacy Policy" : "私隱政策"}
			</a>

			<br/><br/>
			<p>Copyright 2021, F1Plans.com. All Rights Reserved</p>
		</div>
	);
}
