import React, {useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { API, Storage } from 'aws-amplify';

import { useAppContext } from '../../libs/contextLib';

import { 
	calculatePlanExpirationDate, calculateDeliveryWindowEnd,
	getPlanStatus, getDeliveryCountryName,
	translatePlanStatus
} from '../../libs/calculationLib';

import { stringDateToEnglish, jsDateToStringDate } from '../../libs/formatLib';

import {
	PLAN_STATUS_OPTIONS, MAX_FILE_SIZE, ERROR
} from '../../GLOBAL_VARIABLES';

import './Profile.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'

export default function Profile(props){
	const history = useHistory();
	const class_name = props.size;

	const { isAuthenticated, setShowAuthModal, language} = useAppContext();
	const [cookiesIdentityID, ,] = useCookies(['user_identity_id']);

	const [ planStatus, setPlanStatus ] = useState(""); 
	const [ currData, setCurrData ] = useState(null);
	const [ pageIsLoading, setPageIsLoading ] = useState(true);
	const [ isLoading, setIsLoading ] = useState(false);

	const [ passportUploaded, setPassportUploaded ] = useState(null);
	const [ passportVerified, setPassportVerified ] = useState(null);
	const [ passportURL, setPassportURL ] = useState(null);
	const [ passportFileKey, setPassportFileKey ] = useState(null);
	const [ passportError, setPassportError ] = useState("");
	const [ hasPassportError, setHasPassportError ] = useState(false);

	const [ signedTerms, setSignedTerms ] = useState(false);
	const [ termsUpdateLoading, setTermsUpdateLoading ] = useState(false);

	const hiddenFileInput = React.useRef(null); // this for file input

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		if (!isAuthenticated){
			setShowAuthModal(true);
			setPageIsLoading(false);
		} else {
			const data = await API.get(
				'f1plans-api', '/f1plans-api/'+cookiesIdentityID.user_identity_id
			)

			if (data.passport_file_key !== "NONE"){
				const passport_url = await Storage.vault.get(data.passport_file_key)
			  setPassportURL(passport_url);
			  setPassportFileKey(data.passport_file_key);
			}
			await setCurrData(data);
			await setPlanStatus(getPlanStatus(data));
			await setPassportVerified(data.passport_verified === "YES" ? true : false);
			await setPassportUploaded(data.passport_file_key === "NONE" ? false : true);

			if (data.signed_terms_and_conditions === "YES"){
				setSignedTerms(true);
			}

			setPageIsLoading(false);
		}
	}

	async function handleFileUpload(e){
		e.preventDefault();
		setIsLoading(true);

		let file = e.target.files[0];
		if (file && file.size > MAX_FILE_SIZE){
			setHasPassportError(true);

			setPassportError(language === "zh" ? 
				`错误：请上传 <${MAX_FILE_SIZE/1000000}MB 的图片`
				:
				`Error: Please pick file smaller than ${MAX_FILE_SIZE/1000000}MB`
			);
			setIsLoading(false);
			return;
		};
			
		try {
			// store file on cognito
			const filename = currData.sim_user_name + "_Passport"
			const stored = await Storage.vault.put(filename, file);

			var payload = currData;
			payload['passport_file_key'] = stored.key;
			payload['admin_action_required'] = "YES";
			payload['admin_action_required_details'] = "User uploaded passport";

			setCurrData(payload);

			// update database with passport file, and flip admin
			await API.put(
				"f1plans-api", 
				'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
				{ body: payload }
			)
			// ping server to set file
			setPassportFileKey(stored.key);
			const passport_url = await Storage.vault.get(stored.key)
			setPassportURL(passport_url)
			setPassportUploaded(true);
			setHasPassportError(false);
			setIsLoading(false);
		} catch(e){
			setHasPassportError(true);

			setPassportError(language === "zh" ? 
				"文件上传有误，请刷新页面重试或联系客服"
				:
				"Error with file upload, please refresh page and retry or contact customer service"
			);
			setIsLoading(false);
		}
	}

	async function handleCustomFileUpload(){
		if (!passportUploaded){
			hiddenFileInput.current.click();
		}
	}

	async function handleFileDelete(){
		setIsLoading(true);
		try {
			await Storage.vault.remove(passportFileKey);
			// PING server
			var payload = currData;
			payload['passport_file_key'] = "NONE";
			payload['admin_action_required'] = "YES";
			payload['admin_action_required_details'] = "User deleted uploaded passport";

			// ping server to set file
			setPassportURL(null);
			setPassportFileKey(null);
			setPassportUploaded(false);
			setIsLoading(false);


			// update
			await API.put(
				"f1plans-api", 
				'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
				{ body: payload }
			)

			setCurrData(payload);

		} catch(e){
			setHasPassportError(true);

			setPassportError(language === "zh" ? 
				"文件删除有误，请刷新页面重试或联系客服"
				:
				"Error with file deletion, please refresh page and retry or contact customer service"
			);
			setIsLoading(false);
		}
	}

	async function agreeToUserTerms(e){
		e.preventDefault();
		setTermsUpdateLoading(true);

		var payload = currData;
		payload['signed_terms_and_conditions'] = "YES";
		payload['signed_terms_and_conditions_date'] = jsDateToStringDate(new Date());
		payload['admin_action_required'] = "YES";
		payload['admin_action_required_details'] = payload['admin_action_required_details']  + " || User signed terms and conditions";

		try {
			await API.put(
				"f1plans-api",
				'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
				{ body: payload }
			);
			setSignedTerms(true);
			setTermsUpdateLoading(false);
		} catch(e){
			setPassportError(language === "zh" ? 
				"文件删除有误，请刷新页面重试或联系客服"
				:
				"Error with file deletion, please refresh page and retry or contact customer service"
			);
			setTermsUpdateLoading(false);
		}
	}


	return (
		<div className={"ContentPage Profile "+class_name}>
			{ !pageIsLoading ? 
				<div>
					{ isAuthenticated ?
						<div> 
						    { planStatus === PLAN_STATUS_OPTIONS['no_current_plan'] && 
						    	<div>
						   			<h2>
						   				<FormattedMessage id="no_current_plan_header"/>
						   			</h2>
						   			<h3>
						   				<FormattedMessage id="no_current_plan_description"/>
						   			</h3>
						   			<br/>
						    		<span 
						    			className="emphasized-blue-link" 
						    			onClick={()=>history.push('/choose')}
						    		>
						    			<FormattedMessage id="no_current_plan_link"/>
						    		</span>
						    	</div>
						  	}
						  	{ planStatus === PLAN_STATUS_OPTIONS['pending_payment'] && 
						  		<div>
						  			<h2>
						  				<FormattedMessage id="pending_payment_header"/>
						  			</h2>
						   			<h3>
						   				<FormattedMessage id="pending_payment_description"/>
						   			</h3>
						   			<br/>
						    		<span 
						    			className="emphasized-blue-link" 
						    			onClick={()=>history.push('/choose/plan-signup')}
						    		>
						    			<FormattedMessage id="pending_payment_link"/>
						    		</span>
						    	</div>
						  	}
						  	{ planStatus === PLAN_STATUS_OPTIONS['sim_card_to_be_sent']&& 
						    	<p className="alert-text">
						    		<FormattedMessage id="sim_scheduled"/>
						    		{ language === "zh" ? currData.delivery_timeframe : stringDateToEnglish(currData.delivery_timeframe)}
						    		<FormattedMessage id="and"/>
						    		{ language === "zh" ? 
						    			calculateDeliveryWindowEnd(currData.delivery_timeframe)
						    		:
						    			stringDateToEnglish(calculateDeliveryWindowEnd(currData.delivery_timeframe))
						    		}
						    		<FormattedMessage id="sim_scheduled_2"/>
						    	</p>
						  	}
						  	{ planStatus === PLAN_STATUS_OPTIONS['sim_card_to_be_received']&& 
						    	<p className="alert-text">
						    		<FormattedMessage id="sim_in_mail"/>
						    		{currData.delivery_tracking.tracking_number} 
						    	</p>
						  	}
						  	{ planStatus === PLAN_STATUS_OPTIONS['plan_to_be_activated']&& 
						    	<p className="alert-text">
						    		<FormattedMessage id="all_set"/>
						    		<a ahref="/contact">
						    			<FormattedMessage id="activate_contact"/>
						    		</a>
						    		<FormattedMessage id="activate_plan_after"/>
						    		{language === "zh" ? currData.plan_activation_date : stringDateToEnglish(currData.plan_activation_date)}
						    		<FormattedMessage id="activate_plan_after_2"/>
						    		<FormattedMessage id="billing_will_start"/>
						    	</p>
						  	}
						  	{ planStatus === PLAN_STATUS_OPTIONS['active_plan']&& 
						    	<p className="alert-text">
						    		<FormattedMessage id="plan_is_active"/>
						    	</p>
						  	}
						  	{ (
						  		planStatus === PLAN_STATUS_OPTIONS['sim_card_to_be_sent'] || 
						  		planStatus === PLAN_STATUS_OPTIONS['sim_card_to_be_received'] ||
						  		planStatus === PLAN_STATUS_OPTIONS['plan_to_be_activated'] || 
						  		planStatus === PLAN_STATUS_OPTIONS['active_plan']) && 
						  		<div>
							  		
							  		{ (currData.phone_number === "NONE") ? 
							  			<h3 style={{"marginBottom":"2px"}}>
							  				<FormattedMessage id="your_plan"/>
							  			</h3>
							  			:
							  			<div>
							  				<span>
							  					<FormattedMessage id="your_phone_number"/>
							  				</span>
							  				<h3 style={{"marginBottom":"2px", "marginTop":"2px"}}>
							  					{currData.phone_number}
							  				</h3>
							  			</div>
							  		}
							  		<span className="plan-heading">
			          			{ language === "zh" ? 
			          				currData.plan_activation_date 
			          			: 
			          				stringDateToEnglish(currData.plan_activation_date)
			          			}
			          			&nbsp; <FormattedMessage id="to"/> &nbsp;
			          			{ language === "zh" ? 
			          				currData.plan_expiration_date
			          			: 
			          				stringDateToEnglish(currData.plan_expiration_date)
			          			}
	          				</span>
	          				<br/>
			          		<span className="plan-heading">
			          			<FormattedMessage id="status"/>: &nbsp; 
			          			{translatePlanStatus(planStatus, language)}
			          		</span>   
			          		<br/>
			          		
	          				<span className="emphasized-orange">
				    			<em><FormattedMessage id="account_changes"/></em>
				    			&nbsp;
				    			<a target="_blank" href="/contact">
				    				<FormattedMessage id="contact_us_capitalized"/>
				    			</a>
				    		</span>

			          		<br/><br/><br/>
			          		<span className="bold-text">
			          			<FormattedMessage id="plan_details"/>
			          		</span>
			          		<br/>
			          		<FormattedMessage id="sim_user_name"/>: &nbsp;
			          		{currData.sim_user_name}
			          		<br/>
			          		{ currData.sim_card_number !== "NONE" &&
			          			<div>
			          				<span>
			          					<FormattedMessage id="sim_card_number"/>: &nbsp;
			          					{currData.sim_card_number}
			          				</span>
			          				<br/>
			          			</div>
			          		}
			          		<FormattedMessage id="payment_confirmation"/>: &nbsp;
			          		{currData.payment_id}
			          		<br/>
			          		<FormattedMessage id="identity_verification"/>: &nbsp;
			          		{ passportVerified ? 
			          			<span style={{"color":"green"}}>
			          				<FormattedMessage id="completed"/>
			          			</span>
			          		: 
			          			<span style={{"color":"orange"}}>
			          				<FormattedMessage id="pending"/>
			          			</span>
			          		}
			          		<br/><br/>
			          		<a target="_blank" href="/contact">
						    	<FormattedMessage id="request_refund"/>
						    </a>
			          		<br/><br/><br/>
			          		{ !passportVerified && 
			          			<div>
			          				<h3>
			          					<FormattedMessage id="identity_verification_heading"/>
			          				</h3>
			          				<span className="alert-text">
			          					{ passportUploaded ?
			          						<FormattedMessage id="identity_verification_under_review"/>
			          						:
			          						<FormattedMessage id="identity_verification_explanation"/>
			          					}
			          				</span>
			          				<br/>
			          				{ hasPassportError && 
			          					<span className="error-message">{passportError}</span>
			          				}
			          				
			          				<input ref={hiddenFileInput} className="file-input" type="file" onChange={handleFileUpload}></input>

			          				{ passportUploaded ? 
			          					<div>
					          				<button className="custom-file-upload already-uploaded" onClick={handleCustomFileUpload}>
															{ isLoading && <span>&nbsp; &nbsp;</span> }
															<a target="_blank" href={passportURL}>
																<FormattedMessage id="uploaded_passport"/>

															</a>
															&nbsp; &nbsp;
															<i className="delete-file" onClick={handleFileDelete}>
																<FontAwesomeIcon icon="times" size="1x"/>
															</i>
														</button>
														&nbsp; &nbsp;
														{ isLoading && 
															<i>
																<FontAwesomeIcon className="spinning dark" icon="spinner" size="1x"/>
															</i>
														}
													</div>
													:
													<div>
														<button className="custom-file-upload" onClick={handleCustomFileUpload}>
															{ isLoading && <span>&nbsp; &nbsp;</span> }
															<FormattedMessage id="upload_passport"/>
														</button>
														&nbsp; &nbsp;
														{ isLoading && 
															<i>
																<FontAwesomeIcon className="spinning dark" icon="spinner" size="1x"/>
															</i>
														}
													</div>
												}

			          				<br/><br/>
			          			</div>
			          		}

	          				<h3>
	          					<FormattedMessage id="terms_of_service_heading"/>
	          				</h3>
	          				<FormattedMessage id="click_to_view"/> &nbsp;
	          				<a href="/user-terms-of-service" target="_blank">
	          					<FormattedMessage id="terms_of_service"/>
	          				</a>
	          				<br/>
	          				{ !signedTerms ? 
		          				<button className="custom-file-upload" onClick={agreeToUserTerms}>
		          					<FormattedMessage id="click_to_agree"/>
		          				</button>
		          			: 
		          				<div style={{color: "green"}}>
		          					<br/>
		          					<FormattedMessage id="already_agreed"/>
		          				</div>
		          			}
	          				&nbsp; &nbsp;
							{ termsUpdateLoading && 
								<i>
									<FontAwesomeIcon className="spinning dark" icon="spinner" size="1x"/>
								</i>
							}
	          				<br/><br/>

			          		{ planStatus === PLAN_STATUS_OPTIONS['sim_card_to_be_sent'] && 
			          			<div>
			          				<h3>
			          					<FormattedMessage id="delivery_info_heading"/>
			          				</h3>
					          		<FormattedMessage id="delivery_country"/>: &nbsp;
					          		<span className="bold-text">
					          			{getDeliveryCountryName(currData.delivery_country, language)}
					          		</span> 
					          		<br/>
					          		<FormattedMessage id="delivery_timeframe"/>: &nbsp;
					          		<span className="bold-text">
					          			{ language === "zh" ? currData.delivery_timeframe : stringDateToEnglish(currData.delivery_timeframe)}
					          			&nbsp; <FormattedMessage id="to"/> &nbsp;
					          			{language === "zh" ? calculateDeliveryWindowEnd(currData.delivery_timeframe) : stringDateToEnglish(calculateDeliveryWindowEnd(currData.delivery_timeframe))}
					          		</span> 
					          		<br/>
					          		<FormattedMessage id="delivery_contact_name"/>: &nbsp;
					          		<span className="bold-text">{currData.delivery_contact_name}</span> 
					          		<br/>
					          		<FormattedMessage id="delivery_contact_phone"/>: &nbsp;
					          		<span className="bold-text">{currData.delivery_contact_phone}</span> 
					          		<br/>
					          		<FormattedMessage id="delivery_address"/>: &nbsp;
					          		<span className="bold-text">{currData.delivery_address}</span> 
					          		<br/>
					          		{ currData.delivery_notes !== "NONE" && 
						          		<span> 
							          		<FormattedMessage id="delivery_notes"/>: &nbsp;
							          		<span className="bold-text">{currData.delivery_notes}</span> 
						          		</span>
					          		}
	          					</div>
	          				}
			          		<br/><br/>

			          		{ currData.referral_code !== "NONE"  &&
				          		<div>
					          		<h3><FormattedMessage id="referrals_heading"/></h3>
					          		<span className="plan-heading">
					          			<FormattedMessage id="your_referral_code"/>: {currData.referral_code}
					          		</span><br/>
					          		<FormattedMessage id="one_month_free"/>
					          		<br/>
					          		<span className="emphasized-blue"><FormattedMessage id="up_to_two"/></span>
					          		<br/><br/><br/>
					          		<span className="plan-heading">
					          			<FormattedMessage id="your_referrals"/>
					          		</span><br/>
					          		{console.log(currData.referrals_tracking.length)}
					          		{ currData.referrals_tracking.length > 0 ? 
						          		<ul style={{marginLeft:"-18px"}}>
							          		{currData.referrals_tracking.map(item => {
							          			return (
								          			<li>
								          				{item.name}
								          				&nbsp; (
								          				{ item.used === "YES" ? "1-Month Free Applied: "+item.used_for : "1 Month Off will be applied in next billing cycle" }
								          				)
								          			</li>
							          			)})
							          		}
							          	</ul>
							          	: 
							          	<span><FormattedMessage id="no_referrals_yet"/></span>
						          	}
					          		<br/><br/>

					          		<span className="emphasized-blue">
					          			<FormattedMessage id="missing_referrals"/> 
					          			<a href="/contact">
					          				<FormattedMessage id="contact_us_capitalized"/>
					          			</a>
					          		</span>
					          	</div>
				          	}
			          		<br/><br/><br/>
							</div>
						  	}
						  	<br/><br/><br/>
				  		</div>
					:
				  		<h4 className="emphasized-blue-link" onClick={() => setShowAuthModal(true)}>
				  			<FormattedMessage id="login_to_view"/>
				  		</h4>
				  } 
				</div>
			: 
				<div className="FullPageSpinnerContainer">
					<FontAwesomeIcon icon="spinner" size="10x" className="spinning"/>
				</div>
		}
		</div>
    );
}