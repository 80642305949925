import { Auth } from "aws-amplify";

// Auth
import { nanoid } from 'nanoid'; 


import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Hooks
import { useCookies } from 'react-cookie';
import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from '../../libs/hooksLib';
import { onError } from '../../libs/errorLib';

// Auth

import LoaderButton from '../../components/LoaderButton';
import { Modal } from 'react-bootstrap';
import { Form, FormInput, Button } from "shards-react";

import './Auth.scss';

import SocialIcons from '../../components/SocialIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AuthModal(){
	// Other setup
	const history = useHistory();

	// hooks
	const { 
		userHasAuthenticated, 
		showAuthModal, setShowAuthModal, 
		language
	} = useAppContext();
	const [isLoading, setIsLoading] = useState(false); // disables more actions when awaiting api response
	const [fields, handleFieldChange] = useFormFields({ email: "", password: "", password2: "", screen_name: ""});

	// There are three possible modal views: 
	// 1) sign in view - in this view if inSignup=false
	// 2a) sign up view - in tis view if inSignup=true, inScreenName=false
  	const [inSignup, setSignup] = useState(false);
  	const [inVerification, setInVerification] = useState(false);
  	
  	// Error Flagging for each view; one error message var is enough bc we update every time
  	const [hasLoginError, setHasLoginError] = useState(false);
  	const [hasSignupError, setHasSignupError] = useState(false);
  	const [errorMessage, setErrorMessage] = useState('');

	
  	// Handlers for Auth Modal Display
	const handleClose = () => { // When we close modal we want to close everything
		setShowAuthModal(false); // updates context
		setHasLoginError(false);
		setHasSignupError(false);
		setIsLoading(false);
		setErrorMessage('')
	}
	const switchToSignup = () => {
		setSignup(true);
		setInVerification(false);
	};
	const switchToLogin = () => setSignup(false);
	

	// Validating Form Fields
	function validateLogin(){
    	return fields.email.length > 0 && fields.password.length > 0;
  	}

  	function validateSignup(){
  		return fields.email.length > 0 && fields.password.length > 0 && fields.password2.length > 0
  	}


  	// REMINDERS MODAL
  	const [showReminderModal, setShowReminderModal] = useState(false);
  	const [reminderText, setReminderText] = useState('');
  	const handleShowReminder = () => setShowReminderModal(true);
	const handleCloseReminder = () => setShowReminderModal(false);
  	async function displayReminder(message){
  		setShowAuthModal(false);
  		await setReminderText(message);
  		setShowReminderModal(true);
  		await setTimeout(() => setShowReminderModal(false), 2000)

  		return
  	}


  	// Auth Functions


  	async function handleLogin(e){ // non-social DRF JWT Token login
    	e.preventDefault();
    	setIsLoading(true);

	    try {
	        await Auth.signIn(fields.email, fields.password);
	        setIsLoading(false);
	        await displayReminder(language === "zh" ? "您已成功登录！" : "Successfully logged in!")
	        
	        userHasAuthenticated(true);
	        window.location.reload(false); // force refreshes page
	    } catch(e){
	    	if (e.message === "User is not confirmed."){
	    		setSignup(true);
	    		setInVerification(true);
	    		setIsLoading(false);

	    	} else {
	    		await setErrorMessage(e.message)
	        	setHasLoginError(true);
	        	setIsLoading(false);
	    	}
	    }      
    }

  	async function handleSignup(e){ // non-social DRF JWT Token login
		e.preventDefault();
		setIsLoading(true);

	    e.preventDefault();
		try {
	    	await Auth.signUp(fields.email, fields.password);
	    	userHasAuthenticated(false);
	    	setInVerification(true);
	    	setIsLoading(false);
		} catch(e){
	    	await setErrorMessage(e.message)
	    	setHasSignupError(true);
	    	setIsLoading(false);  
	  	}  
	}

	function gotoContactPage(){
		history.push('/contact');
		setShowAuthModal(false);
	}





	return (
		<div className="AuthModal">
			
			<Modal animation={false} id="auth-modal" show={showAuthModal} onHide={handleClose}>
				<Modal.Header>
					<div className="modal-close-button">
						<i onClick={handleClose}>
							<FontAwesomeIcon icon="times" size="1x"/>
						</i>
					</div>
				</Modal.Header>
				{!inSignup &&
					<Modal.Body>
		  				<h2>
		  					{language === "zh" ? "登录" : "Login"}
		  				</h2>
		  				<p>
		  					{ language === "zh" ? "没有账户？": "Don't have an account? "}
		  					<span className="emphasized-blue-link" onClick={switchToSignup}>
		  						{ language === "zh" ? "立即注册" : "Sign up!" }
		  					</span>
		  				</p>
		              	{ hasLoginError && 
		              		<div className="emphasized-orange">{ errorMessage }</div>
		              	}
		              	<Form onSubmit={handleLogin}>
		                	<FormInput id = "email" value={fields.email} onChange={handleFieldChange} placeholder="Email" className="mb-2" type="email"/>
		                  	<FormInput id = "password" value={fields.password} onChange={handleFieldChange} placeholder={language === "zh" ? "密码" : "Password"} className="mb-2" type="password"/>
		                  	<div className="forgot-password">
		                  		<a href="/auth/forgot-password" target="_blank">
		                  			{ language === "zh" ? "忘记密码" : "Forgot password?"}
		                  		</a>
		                  	</div>
		                  	<LoaderButton 
		                  		type="submit" label={language === "zh" ? "登录" : "Login"}
		                  		disabled={!validateLogin()} isLoading={isLoading}
		                  	/>
		              	</Form>
	  				</Modal.Body>
  				}
  				{ inSignup && !inVerification && 
					<Modal.Body>
		  				<h2>
		  					{language === "zh" ? "注册账户" : "Sign up"}
		  				</h2>
		  				
		  				<p>
		  					{language === "zh" ? "已有账户？" : "Already have an account? "}
		  					<span className="emphasized-blue-link" onClick={switchToLogin}>
		  						{language === "zh" ? "请登录" : "Login here!"}
		  					</span>
		  				</p>

		  				<br/>
		  				<p>
		  					{language === "zh" ? "抱歉，本公司暂不开通新账户" : "Sorry, we are not currently accepting signups for new accounts"}
		  				</p>

		  				{/*
		              	{ hasSignupError && 
		              		<div className="emphasized-orange">{ errorMessage }</div>
		              	}
		              	<Form onSubmit={handleSignup}>
		                	<FormInput id = "email" value={fields.email} onChange={handleFieldChange} placeholder="Email" className="mb-2" type="email"/>
		                  	<FormInput id = "password" value={fields.password} onChange={handleFieldChange} placeholder={language === "zh" ? "密码" : "Password"} className="mb-2" type="password"/>
		                  	<FormInput id = "password2" value={fields.password2} onChange={handleFieldChange} placeholder={language === "zh" ? "确认密码" : "Confirm Password"} className="mb-2" type="password"/>
		                  	<LoaderButton 
		                  		type="submit" label={language === "zh" ? "注册" : "Sign Up"}
		                  		disabled={!validateSignup()} isLoading={isLoading}>
		                  	</LoaderButton>
		              	</Form>
		              */}
		            </Modal.Body>
			    }
			    { inSignup && inVerification && 
					<Modal.Body>
		  				<h2>
		  					{language === "zh" ? 
		  						"请确认您的邮箱，确认之后方可登录"
		  					: 
		  						"Verify your email to proceed"
		  					}
		  				</h2>
		  				<p>
		  					{language === "zh" ?
		  					"如未收到确认邮件，请检查您的垃圾邮箱"
		  					: 
		  					"Check your spam if you are not getting it"
		  					}
		  				</p>
		  				<p> <span className="emphasized-blue-link" onClick={switchToLogin}>
		  					{ language === "zh" ? "再次发送确认链接" : "Resend link"}
		  				</span></p>
		  				<p>
		  					{ language === "zh" ? "已完成邮件确认？" : "Finished verification? "}
		  					<span className="emphasized-blue-link" onClick={switchToLogin}>
		  						{ language === "zh" ? "请登录" : "Login here!"}
		  					</span></p>
		            </Modal.Body>
			    }
		            
  				<Modal.Footer>
  					<div style={{"height":"3px"}}/>
  					<span>{ language === "zh" ? "或" : "or"}</span>
  					<div style={{"height":"5px"}}/>
  					<div className="icon-area" onClick={gotoContactPage}>
	  					<span className="emphasized-blue">
	  						{ language === "zh" ? 
	  							"通过 Email、微信或 WhatsApp 开通「F1小能手」服务"
	  						:
	  							"setup plan over Email, Wechat, or WhatsApp"
	  						}
	  					</span>
	  					<SocialIcons/>
                	</div>

  				</Modal.Footer>
  			</Modal>

			<Modal animation={false} backdrop={true} size = "sm" id = "reminder-modal" show={showReminderModal} onHide={handleCloseReminder}>
				<Modal.Header>
				</Modal.Header>
				<Modal.Body>
	  				<p>{reminderText}</p>
  				</Modal.Body>
  				<Modal.Footer></Modal.Footer>
  			</Modal>



		</div>
	);
}