import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

import { Link } from 'react-router-dom';

// Hooks
import { useCookies } from 'react-cookie';
import { useAppContext } from "../libs/contextLib";

// style & UI components
import './CustomNavBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// style & UI components
import ReactTooltip from 'react-tooltip';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Form, FormInput } from "shards-react";


export default function CustomNav(props) {
  // Other setup
  const history = useHistory();
  const [, setCookie, ] = useCookies(['f1_lang']);
  const [, , removeCookieEmail] = useCookies(['user_email']);
  const [, , removeCookieIdentityID] = useCookies(['user_identity_id']);

  // hooks
  const { 
    isAuthenticated, userHasAuthenticated, 
    setIsAuthenticating,
    setShowAuthModal,
    language, setLanguage
  } = useAppContext();

    // profile
    const [showProfile, setShowProfile] = useState(false);
    // const [isLoadingProfile, setIsLoadingProfile] = useState(false);

    const closeProfile = () => {      
      setShowProfile(!showProfile)
    }
    

  // Icon click
  const handleUserIconClick = () => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
    } else {     
      setShowProfile(!showProfile);
    } 
  };


  async function handleLogout(){
    await Auth.signOut();
    setShowProfile(false);
    userHasAuthenticated(false);
    setIsAuthenticating(false);

    removeCookieEmail('user_email');
    removeCookieIdentityID('user_identity_id');
  }

  const languageToENG = () => {
    setLanguage("en");
    setCookie('f1_lang', "en", {path: "/"});
  }
  const languageToCHN = () => { 
    setLanguage("zh");
    setCookie('f1_lang', "zh", {path: "/"});
  }

  const Dropdown = ({ closeProfile }) => {
    const ref = useDetectClickOutside({ onTriggered: closeProfile, disableClick: !showProfile });   
    return (
      <div className="ProfileDropdown" ref={ref}>
        <button onClick={()=>{history.push('/profile'); setShowProfile(false);}}>
          {language === "zh" ? "账户" : "Profile"}
        </button>
        <br/>
        <button onClick={handleLogout}>
          { language === "zh" ? "登出" : "Logout" }
        </button>
      </div>
    );
  };


  return (
    <div className="MonchNavBar">
      <div className = { props.size }>
        <div id = "monch-nav-search" className = "monch-nav-section">
          <span>
             <span className={language=="zh" ? "language-toggle" : "language-toggle selected"} onClick={languageToENG}>EN</span> 
             &nbsp; | &nbsp;
             <span className={language=="zh" ? "language-toggle selected" : "language-toggle"} onClick={languageToCHN}>中</span>
          </span>

        </div>
        <div id = "monch-nav-options" className = "monch-nav-section">
            <i>
              <FontAwesomeIcon icon="user" size="1x" onClick={handleUserIconClick}/>

            </i>
            { showProfile && <Dropdown closeProfile={closeProfile}/> }
        </div>
        <div id = "monch-nav-logo" className = "monch-nav-section">
          <span onClick={()=>history.push('/')}>F1PLANS</span>
        </div>  
      </div>
    </div>
  );

}


