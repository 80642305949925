import React from 'react';
import './Card.scss';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Card(props){
	const history = useHistory();

	function redirect(){
		history.push(props.redirect_url)
	}
	
	return(
		<div className="Card">
			<div className="text">
				<div className="plan-duration">
					{ props.starred && <span>&nbsp; &nbsp; &nbsp;</span>}
					{props.name}
					{ props.starred && 
						<span>&nbsp;
							<FontAwesomeIcon icon="certificate" size="1x" className="star-icon"/>
						</span>
					}
				</div>
				<span>{props.description}</span>
				{ props.deal_text ?
					<p className="deal-text">
						{props.deal_text}
					</p>
					:
					<p className="deal-text">
						&nbsp;
					</p>
				}

				<button onClick={redirect} className="select-plan-button">
					{props.button_text}
				</button>
			</div>
		</div>
	);

}