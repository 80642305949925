import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './LoaderButton.scss';


export default function LoaderButton({type="", onClick=(()=>{}), isLoading, disabled=false, label, style='dark-button'}){

	return (
		<span>
			{ type === "submit" && 
				<button className={style+" LoaderButton"} disabled={disabled || isLoading} type={type}>
					{label}
					{isLoading && <FontAwesomeIcon icon="spinner" size="1x" className="spinning"/>}
				</button>
			}
			{ type === "standalone" && 
				<button className={style+" LoaderButton standalone" } disabled={disabled || isLoading} onClick={onClick} >
				<strong>{label}</strong>
				{isLoading && <FontAwesomeIcon icon="spinner" size="1x" className="spinning"/>}
			</button>
			}
		


			
		</span>
	);
}