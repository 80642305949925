import React from 'react';

export default function UserTerms(){
	return (
		<div className="ContentPage	">
			<h2>F1PLANS: Terms of Service</h2>
			<p>Last updated: June 26, 2021</p>

			<div className="TermsContent">
				<p>
					These Terms of Service constitute a legally binding 
					agreement made between you ("the User") and F1PLANS LLC ("F1PLANS").   
				</p>

				<h4>Service Terms</h4>
				<ul>
					<li>
						The User shall not circumvent any F1PLANS guidelines on service usage. F1PLANS 
						shall not be held responsible for any delays and issues caused by the User's 
						lack of adherence to specified guidelines. F1PLANS may also assess additional 
						fees as it deems necessary. Specifically: 
						<ul>
							<li> 
								If the User ignores the "no SIM placement in device until  
								activation date" rule, the User may be subjected to one additional month
								of service charges
							</li>
							<li>
								If the User ignores the "no SIM placement in device until 
								physically present in the US" rule, the User may be subjected to delays 
								in service activation and an additional SIM service fee
							</li>
							<li>
								If the User deliberately attempts to circumvent the "no international calling" 
								restriction, the User may be assessed an additional service fee
							</li>
						</ul>
					</li>
					<li>
						All information the User provides to F1PLANS should be true
						to the best of the User's knowledge. Under no circumstances 
						should the User attempt to deceive F1PLANS through false 
						information, the misrepresentation of information, 
						or the omission of information.
					</li>
					<li>
						Any activity conducted by the User using services or 
						products provided by F1Plans shall, without exception, abide by 
						relevant State and Federal law.
					</li>
					<li>
						The User shall not share any product or service provided by F1PlANS
						to the User with any other party, unless the User notifies F1PLANS and 
						follows all relevant transition protocol.
					</li>
				</ul>
				<br/>

				<h4>Indemnification</h4>
				<ul>
					<li>
						F1PLANS strive to maintain consistent network connectivity and  
						customer service to all clients, but does not guarantee uninterrupted 
						cell service and timely customer service.
					</li>
					<li>
						F1PLANS will not take responsibility for any loss incurred by the
						User as a result of the usage (or outage) of any F1PLANS products 
						and services. 
					</li>
				</ul>
				<br/>

				<h4>Renewal & Refund</h4>
				<ul>
					<li>
						During the 3 months before the expiration of the User's plan, 
						F1PLANS will provide the User with at least three correspondences 
						offering the opportunity for plan extension. If the User has not 
						made a renewal payment at the time of expiration of the User's 
						current plan, F1PLANS reserve the right to end the User's service 
						without any additional notice.
					</li>
					<li>
						After two months on any plan, the User may request for their service to 
						end early and receive a full refund for the remaining billing period. 
						Refunds must be requested 30 days prior to the new service 
						end-date. When a refund is requested, all 
						promotional savings and referral bonuses previously applied to the 
						account are voided, and will not be refunded back to the User. When
						the User requests a refund, F1PLANS will process the refund within 
						a maximum of 30 days.
					</li>
				</ul>
			</div>
		</div>
	);
}