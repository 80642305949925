const config = {
  // prod key
  STRIPE_KEY: "pk_live_51IogGYA5lmdikmTQTOX5eGd7HdOsgFVge5iLbQRne1zmTXrcKYnMjfP2Y74nAMpPUBjS7FdpiuIpWxmLQhrhToKT00Mwhbt6Pv",
  // test key
  // STRIPE_KEY: "pk_test_51IogGYA5lmdikmTQtbrFRepFjND6nk3rMOEpblpZ3Fk6JLQPt3XtwXiQCeMqgey7l9uKn9HBsLZZALJkBpR0x20700OmrV9KY5",
  s3: {
    REGION: "us-east-1",
    BUCKET: "f1plans-document-upload",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://0avommeytc.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_sYZp8RxHb",
    APP_CLIENT_ID: "4unpj556dpu9pqr33eqg0cb2is",
    IDENTITY_POOL_ID: "us-east-1:46caba5f-c341-40f5-8096-4dcf004facc1",
  },
};

export default config;