import React, { useState, useEffect} from 'react';
import { Auth } from "aws-amplify";

import { useLocation } from 'react-router-dom';

import './Auth.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppContext } from "../../libs/contextLib";
import { IntlProvider, FormattedMessage } from 'react-intl';
import { authTranslations } from '../../translations/authTranslations'

export default function VerifyEmail(){
  return (
    <div>Test verify email</div>
  );
}

// export default function VerifyEmail(){
// 	const { language } = useAppContext();

// 	const queryString = require('query-string');
// 	const token = queryString.parse(useLocation().search).token;

// 	const [isConfirmed, setIsConfirmed ] = useState(false);
// 	const [isError, setIsError ] = useState(false);

// 	useEffect(() => {
//     	onLoad();
//     }, []);

//   	async function onLoad(){
//     	try {
//       		await verifyEmailHelper(token);
//       		setIsConfirmed(true);
// 	    } catch(e) {
// 	    	setIsError(true);
// 	    }
// 	}

// 	return (
// 		<IntlProvider locale={language} messages={authTranslations[language]} >
// 			<div className="VerifyEmail">
// 				{!isConfirmed  && !isError &&
// 					<div>
// 						<div className="icon-div"><FontAwesomeIcon icon="spinner" size="10x" className="spinning"/></div>
// 						<br></br>
// 						<h5><FormattedMessage id="emailBeingVerified"/></h5>
// 					</div>
// 				}
// 				{isConfirmed && !isError &&
// 					<div>
// 						<div className="icon-div"><FontAwesomeIcon icon="check-circle" size="10x" className="success"/></div>
// 						<br></br>
// 						<h5><FormattedMessage id="emailVerified"/></h5>
// 					</div>
// 				}
// 				{isError && 
// 					<div>
// 						<div className="icon-div"><FontAwesomeIcon icon="exclamation-triangle" size="10x" className="warning"/></div>
// 						<br></br>
// 						<h5><FormattedMessage id="emailCantBeVerified"/></h5>
// 					</div>
// 				}
				
// 			</div>
// 		</IntlProvider>
// 	);
// }