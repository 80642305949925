// react imports
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// component imports
import HomeContainer from './containers/Home/HomeContainer';
import ProfileContainer from './containers/Profile/ProfileContainer';
import ChoosePlanContainer from './containers/ChoosePlan/ChoosePlanContainer';
import PlanSignupContainer from './containers/PlanSignup/PlanSignupContainer';
import FriendsSignupContainer from './containers/FriendsSignup/FriendsSignupContainer';

import VerifyEmail from "./containers/Auth/VerifyEmail";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword";

import UserTerms from './containers/UserTerms';

import Contact from './containers/Contact';
import PrivacyPolicy from './containers/PrivacyPolicy';
import TermsAndConditions from './containers/TermsAndConditions';

import NotFound from './containers/NotFound';


export default function Routes(){
	return (
		<Switch>
			<Route exact path="/">
				<HomeContainer/>
			</Route>
			<Route exact path="/profile">
				<ProfileContainer/>
			</Route>
			<Route exact path="/choose">
				<ChoosePlanContainer/>
			</Route>
			<Route exact path="/friendsandfamily/signup">
				<FriendsSignupContainer/>
			</Route>
			<Route exact path="/choose/plan-signup">
				<PlanSignupContainer/>
			</Route>
			<Route exact path="/auth/forgot-password">
				<ForgotPassword/>
		    </Route>
		    <Route exact path="/auth/password-reset">
		    	<ResetPassword/>
		    </Route>
		    <Route exact path="/auth/verify-email">
		    	<VerifyEmail/>
		    </Route>
		    <Route exact path="/contact">
		    	<Contact/>
		    </Route>
		    <Route exact path="/privacy-policy">
		    	<PrivacyPolicy/>
		    </Route>
		    <Route exact path="/terms-and-conditions">
		    	<TermsAndConditions/>
		    </Route>
		    <Route exact path="/user-terms-of-service">
		    	<UserTerms/>
		    </Route>
			<Route>
				<NotFound/>
			</Route>
		</Switch>
	)
}
