export function addMonthsToStringDate(string_date, num_month_added){
	var date = new Date(string_date); // get current date
	
	date.setMonth(date.getMonth()+num_month_added); // add X months to date

	console.log("date is")
	console.log(date)

	var year = date.getFullYear().toString();
	var month = (date.getMonth()+1).toString();
	var day = date.getDate().toString();

	if (date.getMonth() < 9) {
		month = "0".concat(month)
	}

	if (date.getDate() < 10) {
		day = "0".concat(day)
	}

	var new_string_date = year.concat('-',month,'-',day)

	return new_string_date
}

export function addDaysToStringDate(string_date, num_days_added){
	var date = new Date(string_date); // get current date
	
	date.setDate(date.getDate()+num_days_added); // add X months to date
	return jsDateToStringDate(date)
}

export function stringDateToEnglish(string_date){
	const day = string_date.slice(8,10);
	const month = convertMonthStringToWords(string_date.slice(5,7));
	const year = string_date.slice(0,4);

	return month + " " + day + ", " + year
}


export function jsDateToStringDate(input_date){
	const date = new Date(input_date);
	var year = date.getFullYear().toString();
	var month = (date.getMonth()+1).toString();
	var day = date.getDate().toString();

	if (date.getMonth() < 9) {
		month = "0".concat(month)
	}
	if (date.getDate() < 10) {
		day = "0".concat(day)
	}

	return year.concat('-',month,'-',day)
}

function convertMonthStringToWords(month_string) {
  switch(month_string) {
    case "01": return "Jan"
    case "02": return "Feb"
    case "03": return "Mar"
    case "04": return "Apr"
    case "05": return "May"
    case "06": return "June"
    case "07": return "July"
    case "08": return "Aug"
    case "09": return "Sep"
    case "10": return "Oct"
    case "11": return "Nov"
    case "12": return "Dec"
    default: return "ERROR"
  }
}

