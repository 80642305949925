import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import config from "../../config";

import { useCookies } from 'react-cookie';
import { useAppContext } from '../../libs/contextLib';

import {
  PAYMENT_METHOD_CARD
} from '../../GLOBAL_VARIABLES';

import { useFormFields } from '../../libs/hooksLib';
import { Form, FormInput, Button } from "shards-react";
import LoaderButton from './../../components/LoaderButton';

import {nanoid} from 'nanoid';  

import { Modal } from 'react-bootstrap';

import { Elements, StripeProvider, CardElement, injectStripe } from "react-stripe-elements";


import './CardCheckout.scss';

function CardCheckout(props){


	const history = useHistory();
  const { 
    language, setHasPaymentError
  } = useAppContext();

  const [cookiesEmail, ,] = useCookies(['user_email']);
  const [cookiesIdentityID, ,] = useCookies(['user_identity_id']);

  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [fields, handleFieldChange] = useFormFields({ 
		name_on_card: "",
	});

  const [showRedirectModal, setShowRedirectModal] = useState(false);


  function validatePayment(){
  	return true;
  }

  async function billUser(promo_code, source){
    setIsLoading(true);
  
  	try {
      // get current user row
      const res = await API.get(
        'f1plans-api', '/f1plans-api/'+cookiesIdentityID.user_identity_id
      )

      // generate payment id
      const payment_id = nanoid(8);
      const payment_description = "F1Plans " + res.plan_selected + " Service (ID: " + payment_id + "; PROMO: " + promo_code + ")";

      // ping billing api
      await API.post(
        "f1plans-api", "/billing", 
        { body: 
          { 
            plan_selected: res.plan_selected, 
            description: payment_description,
            promo_code: props.promo_code,
            source: source,
            receipt_email: cookiesEmail.user_email
          }
        }
      );

      var payload = res;
      payload['payment_id'] = payment_id;
      payload['pending_payment'] = "NO";

      const new_string = `User has paid, with ID: ${payment_id} and promo code: ${props.promo_code}`
      payload['user_action_history'] = res.user_action_history.concat([new_string])

      payload['admin_action_required'] = "YES"
      payload['admin_action_required_details'] = "User has paid for plan"

      payload['payment_method'] = PAYMENT_METHOD_CARD
      payload['promo_code'] = props.promo_code


      // if that works, ping update api to add in new fields
      await API.put(
        'f1plans-api', // api name 
        '/f1plans-api/'+cookiesIdentityID.user_identity_id, // path
        { body: payload } 
      )

    } catch(e) {
      setIsLoading(false);
      console.log("INSIDE CARD PAYMENT, ERROR IS")
      console.log(e.message)
      throw new Error('Invalid Card');
    }

  	setIsLoading(false);

  }

  async function handlePaymentSubmit(e){
  	e.preventDefault();

  	console.log("inside handle payment submit")
  	setIsProcessing(true);

    const { token, error } = await props.stripe.createToken({
      name: fields.name_on_card,
    });

    setIsProcessing(false);

    if (error){
    	alert(error.message);
    	return;
    }

    setIsLoading(true);
    try {
    	const plan_selected = fields.plan_selected;
    	await billUser(props.promo_code, token.id);

    	// alert("Card has been charged successfully! You will be redirected in 3 seconds");

      setShowRedirectModal(true);

      setTimeout(()=> {
        setShowRedirectModal(false);
        history.push("/profile")
      }, 3000);
    	setIsLoading(false);

    } catch(e) {
    	setIsLoading(false)
      setHasPaymentError(true);
    }
  }

  return (
  	<div className="CardCheckout">
  		

  		<Form onSubmit={handlePaymentSubmit}>
  			{ language === "zh" ? 
          "持卡人姓名"
        : 
          "Cardholder Name"
        }

        <FormInput 
          style={{"maxWidth":"300px"}}
          id = "name_on_card" value={fields.name_on_card} 
          onChange={handleFieldChange} 
          type="text"
        />

  			<br/>
        <div className="stripe-cc-info-container">
    			<CardElement
  	        className="card-field"
  	        onChange={(e) => setIsCardComplete(e.complete)}
  	        style={{
  	          base: {
  	            fontSize: "16px",
  	            color: "#495057",
  	            fontFamily: "'Open Sans', sans-serif",
  	          },
  	        }}
  	      />
        </div>
        <br/><br/>
        { language === "zh" ?
          <LoaderButton 
            type="submit" label="确认付款"
            disabled={!validatePayment()} isLoading={isLoading || isProcessing}
          />
        : 
          <LoaderButton 
            type="submit" label="Confirm Payment"
            disabled={!validatePayment()} isLoading={isLoading || isProcessing}
          />

        }
	      
  		</Form>

      <Modal 
        animation={false} backdrop={true} size = "sm" 
        id = "reminder-modal" show={showRedirectModal} 
      >
        <Modal.Header>
        </Modal.Header>
          <Modal.Body>
            <p>Success! You will redirected shortly</p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>



  	</div>
  );

}
export default injectStripe(CardCheckout);	

