import {
	TAX_RATE,
	MONTHLY_COST_12M, MONTHLY_COST_6M, MONTHLY_COST_2M,
	FAF_VERIZON_UNLIMITED_START, FAF_VERIZON_UNLIMITED_PLUS, FAF_TMOBILE_UNLIMITED_FAMILY,
	TEST_CODES,
	USER_REFERRAL_CODES,
	OFF20,
	EXPIRED
} from '../FRONTEND_BILLING_VARIABLES';

// LOGIC BEFORE RETURN STATEMENT SHOULD BE BASED OFF BACKEND "BILLING_VARIABLES" FILE
export function calculateCost(plan, promo_code){

	var num_months = 0;
	var price_per_month = 0;
	if (plan === "12M"){
		num_months = 12;
		price_per_month = MONTHLY_COST_12M;
	} else if (plan === "6M"){
		num_months = 6;
		price_per_month = MONTHLY_COST_6M;
	} else if (plan === "2M"){
		num_months = 2;
		price_per_month = MONTHLY_COST_2M;
	}

	const plan_cost = price_per_month * num_months;
	var discounted_plan_cost = plan_cost;

	if (plan === "12M" && OFF20.includes(promo_code) && !(EXPIRED.includes(promo_code))){
		discounted_plan_cost = discounted_plan_cost - 20;
	}

	const taxes = discounted_plan_cost * TAX_RATE;
	const total_cost = discounted_plan_cost + taxes;

	// for testing purposes only
	if (TEST_CODES.includes(promo_code)){
		return {
			price_per_month: (price_per_month/100).toFixed(4),
			num_months: num_months,
			plan_cost: (plan_cost/100).toFixed(4),
			discounted_plan_cost: (discounted_plan_cost/100).toFixed(2),
			taxes: (taxes/100).toFixed(4),
			total_cost: (total_cost/100).toFixed(4)
		};
	}

	return {
		price_per_month: price_per_month,
		num_months: num_months,
		plan_cost: plan_cost.toFixed(2),
		discounted_plan_cost: discounted_plan_cost.toFixed(2),
		taxes: taxes.toFixed(2),
		total_cost: total_cost.toFixed(2)
	};
}

export function calculatePromoCodeErrorMessage(plan, promo_code){
	if (TEST_CODES.includes(promo_code)){
		return "TEST_CODE_DO_NOT_USE"
	}
		
	if (EXPIRED.includes(promo_code)){
		return "CODE_EXPIRED"
	} else if (OFF20.includes(promo_code) && plan !== "12M"){
		return "MUST_PICK_12M_PLAN"
	} else if (!OFF20.includes(promo_code)){
		return "INVALID_CODE"
	}

	return "NONE"
}