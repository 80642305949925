const contactTranslations = {
	'en': {
		contact_us: "CONTACT US",
		contact_blurb: "Reach out via any channel for general inquries, sign up, account changes, and refunds",
		wechat: "WeChat",
		add_by_phone: "Add by phone number"
	},
	'zh': {
		contact_us: "联系我们",
		contact_blurb: "咨询、开通服务、修改账户信息、退款等需求请通过以下方式联系",
		wechat: "微信",
		add_by_phone: "通过电话号码添加"
	}
}

export { contactTranslations };