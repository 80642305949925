import { addMonthsToStringDate, addDaysToStringDate } from './formatLib.js';

import {
	PLAN_2M, PLAN_6M, PLAN_12M,
	PLAN_STATUS_OPTIONS, 
	DELIVERY_TIMEFRAME_DAYS,
	ERROR, 
} from '../GLOBAL_VARIABLES';

import {
	MONTHLY_COST_2M, MONTHLY_COST_6M, MONTHLY_COST_12M,
} from '../FRONTEND_BILLING_VARIABLES';


export function calculatePlanExpirationDate(activation_date, plan_selected){
	var months_added = 0;
	if (plan_selected === PLAN_2M){
		months_added = 2;
	} else if (plan_selected === PLAN_6M){
		months_added = 6;
	} else if (plan_selected === PLAN_12M){
		months_added = 12;
	} 

	const expiration_date = addMonthsToStringDate(activation_date, months_added)

	return expiration_date
}

export function calculateDeliveryWindowEnd(string_date){
	// delivery window is 7-day
	return addDaysToStringDate(string_date, DELIVERY_TIMEFRAME_DAYS)
}

export function getPlanCost(plan_selected){
	if (plan_selected === PLAN_2M){
		return MONTHLY_COST_2M
	} else if (plan_selected === PLAN_6M){
		return MONTHLY_COST_6M
	} else if (plan_selected === PLAN_12M){
		return MONTHLY_COST_12M
	}
}

export function getPlanStatus(data){

	if (Object.keys(data).length === 0){ // if no data
		return PLAN_STATUS_OPTIONS['no_current_plan']
	} else if (data.pending_payment === "YES"){ // if user hasnt paid yet 
		return PLAN_STATUS_OPTIONS['pending_payment']
	} else if (data.delivery_tracking.sent === "NO"){ // if sim has not yet been sent
		return PLAN_STATUS_OPTIONS['sim_card_to_be_sent']
	} else if (data.delivery_tracking.received === "NO"){
		return PLAN_STATUS_OPTIONS['sim_card_to_be_received'] // if sim is sent but not received
	} else if (data.plan_active === "NO"){
		return PLAN_STATUS_OPTIONS['plan_to_be_activated'] // if sim received, but not yet activation date
	} else if (data.plan_active === "YES"){
		return PLAN_STATUS_OPTIONS['active_plan'] // active plan
	} else if (data.plan_expired === "YES"){
		return PLAN_STATUS_OPTIONS['expired_plan'] // expired plan
	} else {
		return ERROR
	}
}

export function getSocialName(platform, language){
	if (platform === "wechat" && language === "zh"){
		return "微信"
	} else if (platform === "wechat"){
		return "Wechat"
	} else if (platform === "whatsapp"){
		return "WhatsApp"
	} else if (platform === "email"){
		return "Email"
	}
}

export function getDegreeTypeName(degree_type, language){
	if (degree_type === "bachelors" && language === "zh"){
		return "本科"
	} else if (degree_type === "bachelors"){
		return "Bachelor's"
	} else if (degree_type === "masters" && language === "zh"){
		return "研究生"
	} else if (degree_type === "masters"){
		return "Master's"
	} else if (degree_type === "doctorate" && language === "zh"){
		return "博士"
	} else if (degree_type === "doctorate"){
		return "Doctorate"
	} else if (degree_type === "secondary" && language === "zh"){
		return "初高中"
	} else if (degree_type === "secondary"){
		return "Secondary"
	} else if (degree_type === "other" && language === "zh" ){
		return "其它"
	} else if (degree_type === "other"){
		return "Other"
	}
}

export function getDeliveryCountryName(country, language){
	if (country === "china" && language === "zh"){
		return "中国大陆"
	} else if (country === "china"){
		return "Mainland China"
	} else if (country === "hk" && language === "zh"){
		return "香港"
	} else if (country === "hk"){
		return "Hong Kong"
	} else if (country === "us" && language === "zh"){
		return "美国"
	} else if (country === "us"){
		return "United States"
	}
}

export function translatePlanStatus(plan_status, language){
	if (language !== "zh"){
		return plan_status
	} else if (plan_status === "NO CURRENT PLAN"){
		return "尚未选择电话服务"
	} else if (plan_status === "PENDING PAYMENT"){
		return "等待付款"
	} else if (plan_status === "SIM CARD TO BE SENT"){
		return "等待 SIM 卡"
	} else if (plan_status === "SIM CARD TO BE RECEIVED"){
		return "等待 SIM 卡签收"
	} else if (plan_status === "PLAN TO BE ACTIVATED"){
		return "等待 SIM 卡激活"
	} else if (plan_status === "ACTIVE PLAN"){
		return "已开通电话服务"
	} else if (plan_status === "EXPIRED PLAN"){
		return "电话服务周期已结束"
	}
}

export function translatePromoCodeError(promo_code_error, language){
	if (promo_code_error === "USER_REFERRAL" && language === "zh"){
		return "已成功使用推荐码！让朋友用您的推荐码购买「全球无限卡12个月」套餐双方均可免$20话费哦～"
	} else if  (promo_code_error === "USER_REFERRAL"){
		return "Thank you for using a referral code. You can also give $20 and get $20 off your next bill when your friends use your referral code to purchase the 12-Month plan!"
	} else if (promo_code_error === "CODE_EXPIRED" && language === "zh"){
		return "优惠码以过期"
	} else if (promo_code_error === "CODE_EXPIRED") {
		return "Sorry, this code has expired"
	} else if (promo_code_error === "MUST_PICK_12M_PLAN" && language === "zh") {
		return "此优惠码只能在「无限卡12个月」套餐里使用"
	} else if (promo_code_error === "MUST_PICK_12M_PLAN") {
		return "This '$20 Off' code only applies to the 12-Month Plan"
	} else if (promo_code_error === "INVALID_CODE" && language === "zh") {
		return "无效优惠码"
	} else if (promo_code_error === "INVALID_CODE") {
		return "Invalid code"
	} else if (promo_code_error === "TEST_CODE_DO_NOT_USE"){
		return "TEST CODE DO NOT USE"
	} else {
		return ""
	}
}






