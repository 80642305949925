import React, {useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../libs/contextLib";

import './Home.scss';

import SocialIcons from '../../components/SocialIcons';

import MailIcon from '../../images/mail_icon.png';
import WechatIcon from '../../images/wechat_icon.jpg';
import WhatsappIcon from '../../images/whatsapp_icon.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga';

export default function Home(props){

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	

	const history = useHistory();
	const class_name = props.size;
	const { language } = useAppContext();


	return (
		<div className={"ContentPage Home "+class_name}>
	    	<div>
		    	<div className="home-content">
		            <h3><br></br></h3>

		    		{ language === "zh" ? 
			    		<div className="slogan-area">
			    			<div className="slogan" id="slogan-1-chinese">
			    				<span style={{letterSpacing:"3px"}}>F1Plans</span> 无限卡
			    			</div>
			    			<div className="slogan" id="slogan-2-chinese">
			    				学生省心 家人放心
			    			</div>
			    			<div className="slogan" id="slogan-3-chinese">
			    				专为美国留学生打造的贴心电话服务
			    			</div>
			    			<div id="services-chinese">
			    				国内送卡 &#8226; 微信服务 &#8226; 紧急补卡 &#8226; 学生咨询
			    				<br/>
			    				开通无需美国
			    				<span style={{letterSpacing:"0"}}>SSN</span> &#8226; <span style={{letterSpacing:"0"}}>Verizon</span> 官方合作伙伴
			    			</div>
			    		</div>
			    	:
			    		<div className="slogan-area">
			    			<div className="slogan" id="slogan-1-english">
			    				the EVERYWHERE
			    			</div>
			    			<div className="slogan" id="slogan-2-english">
			    				UNLIMITED PLAN
			    			</div>
			    			<div className="slogan" id="slogan-3-english">
			    				a phone service tailored for international students
			    			</div>
			    			<div id="services-english">

			    				Mainland+HK SIM Delivery &nbsp; &#8226; &nbsp; No-Wait IM Servicing &nbsp; &#8226; &nbsp; Rapid SIM Replacement
			    				<br/>
			    				Bypass US SSN and Credit Card Requirements &nbsp; &#8226; &nbsp; Official Verizon Partner
			    			</div>

			    		</div>
			    	}
		    		<br/>


		    		<div className="slogan-area">
		    			<b>
		    				<FormattedMessage id="getStarted"/>
		    			</b>
		    			<br/>
		                <button className="dark-button flashing" onClick={()=>{history.push('/choose')}}>
		                   	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
		                   	<FormattedMessage id="getStartedButton"/> 
		                    &nbsp; &nbsp; &nbsp;  &nbsp;
		                    <FontAwesomeIcon className="flashing-icon" icon="mouse-pointer"/>
		                </button>
		                <div style={{height:"8px"}}/>
		                <FormattedMessage id="or"/>
		                <div style={{height:"8px"}}/>
		                <button className="alt-get-started-options" onClick={()=>{history.push('/contact')}}>
		                	<div className="helptext">
		                		<FormattedMessage id="getStartedWithSocial"/>
		                	</div>
		                	<SocialIcons />
		                </button>
	                </div>

	                <br></br>
	                <br></br>
		    	</div>
		    </div>
		</div>
    );
}