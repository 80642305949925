const PLAN_2M = "2M"
const PLAN_6M = "6M"
const PLAN_12M = "12M"

// plan options
const VERIZON_UNLIMITED_START = "VERIZON_UNLIMITED_START"
const VERIZON_UNLIMITED_PLUS = "VERIZON_UNLIMITED_PLUS"
const TMOBILE_FAMILY = "TMOBILE_UNLIMITED_FAMILY"

const PAYMENT_METHOD_CARD = "CARD"
const PAYMENT_METHOD_ALIPAY = "ALIPAY"

const PLAN_STATUS_OPTIONS = {
	no_current_plan: "NO CURRENT PLAN",
	pending_payment: "PENDING PAYMENT",
	sim_card_to_be_sent: "SIM CARD TO BE SENT",
	sim_card_to_be_received: "SIM CARD TO BE RECEIVED",
	plan_to_be_activated: "PLAN TO BE ACTIVATED",
	active_plan: "ACTIVE PLAN",
	expired_plan: "EXPIRED PLAN"
}


const ORDER_TO_SIM_DELIVERY_BUFFER_DAYS = 3
const DELIVERY_START_TO_ACTIVATION_BUFFER_DAYS = 1
const DELIVERY_TIMEFRAME_DAYS = 2

const LATEST_POSSIBLE_START_DATE = "2022-06-02"

const MAX_FILE_SIZE = 4000000 // 4MB

const ERROR = "ERROR"

const ALIPAY_SUCCEEDED = "succeeded"
const ALIPAY_FAILED = "failed"

export { 
	PLAN_2M, PLAN_6M, PLAN_12M,
	VERIZON_UNLIMITED_START, VERIZON_UNLIMITED_PLUS, TMOBILE_FAMILY,
	PAYMENT_METHOD_CARD, PAYMENT_METHOD_ALIPAY,
	PLAN_STATUS_OPTIONS,
	ORDER_TO_SIM_DELIVERY_BUFFER_DAYS, DELIVERY_START_TO_ACTIVATION_BUFFER_DAYS,
	DELIVERY_TIMEFRAME_DAYS, LATEST_POSSIBLE_START_DATE,
	MAX_FILE_SIZE,
	ERROR,
	ALIPAY_SUCCEEDED, ALIPAY_FAILED
}