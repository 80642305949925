const planSignupTranslations = {
	'en': {
		already_have_plan: "Looks like you already have a plan, you'll be redirected shortly. If not, please click on the User icon and go to your profile.",
		plan_details_heading: "Plan Details",
		payment_heading: "Payment",
		plan_details: "PLAN DETAILS",
		everywhere_unlimited: "The Everywhere, Unlimited Plan",
		plan_duration: "SELECT YOUR PLAN",
		cost: "Cost",
		cost_unit: "/ month",
		social_instead: "Want to sign up via Email, Wechat, or Whatsapp instead? ",
		contact_us: "Contact Us",

		contact: "CONTACT",
		sim_user_name: "SIM User Full Name",
		wechat_username: "Wechat Username",
		whatsapp_phone_number: "WhatsApp Phone Number",
		preferred_contact_method: "Preferred Contact Method",
		wechat: "Wechat",

		student_info: "STUDENT INFO",
		school_name: "School Name",
		degree_type: "Degree Type",

		existing_number: "EXISTING U.S. NUMBER",
		existing_number_blurb: "If you'd like to keep your current US number, please answer the following",
		current_us_number: "Current US Number",
		current_us_carrier: "Current US Carrier",

		delivery: "SIM DELIVERY",
		delivery_timeframe: "SIM CARD DELIVERY DATE",
		delivery_timeframe_2: "SIM Card Delivery Timeframe: ",
		delivery_window_1: "Please expect a + / - ",
		delivery_window_2: " day delivery window",
		delivery_country: "Delivery Country",
		plan_activation_date: "PlAN ACTIVATION DATE",
		plan_activation_date_2: "Plan Activation Date: ",
		plan_activation_date_start: "Must be after SIM card delivery",
		plan_must_be_activated_in_us: "SIM card can only be activated while student is in the US. If initial SIM card insertion takes place outside the US, the SIM will not work",
		delivery_contact_name: "Delivery Contact Name",
		delivery_contact_phone: "Delivery Contact Phone",
		delivery_address: "Delivery Address",
		delivery_notes: "Delivery Notes",
		to: "to",

		review_order: "REVIEW YOUR ORDER",
		edit_plan_details: "Edit plan details",

		confirm_delivery_info: "CONFIRM SIM DELIVERY INFO",
		change_delivery_details: "Change delivery details",

		payment: "PAYMENT",
		phone_service_cost: "Phone Service",
		taxes: "Taxes & Fees",
		month: " Months",
		total_cost: "Total",
		usd: " USD",
		usd_per_month: " USD/month",
		promo_code: "Promo Code",
		apply: "Apply",
		discounted_price: "Discounted Price",
		has_payment_error: "There's a payment error, you have not been charged. Please try again or ",
		contact_us_short: "contact us",

		// profile page specific
		no_current_plan_header: "An Everywhere, Unlimited Plan with Exceptional Service",
		no_current_plan_description: "Starting as low as $45.99/month, with up to 2 months off per year from bonuses",
		no_current_plan_link: "Get started now",
		pending_payment_header: "Just One Step Away.",
		pending_payment_description: "We have your plan information, pay now to unleash your everywhere unlimited potential!",
		pending_payment_link: "Review plan and pay",
		sim_scheduled: "Your SIM card is scheduled to arrive betweeen ",
		and: " and ",
		sim_scheduled_2: " at the address specified below",
		sim_in_mail: "Your SIM card is in the mail! Tracking #: ",
		all_set: "You're all set! ",
		activate_contact: "Email, WeChat, or WhatsApp us",
		activate_plan_after: " to activate your plan on ",
		activate_plan_after_2: " or after. ",
		billing_will_start: "(NOTE: student and SIM card must be present in the US at time of activation)",
		plan_is_active: "Your plan is active",
		your_plan: "YOUR PLAN",
		your_phone_number: "YOUR PHONE NUMBER",
		account_changes: "Any questions or account changes? ",
		contact_us_capitalized: "Contact Us",
		sim_card_number: "SIM Card #",
		payment_confirmation: "Payment Confirmation",
		identity_verification: "Identity Verification",
		pending: "PENDING",
		completed: "COMPLETED",
		request_refund: "Request Refund",
		identity_verification_heading: "IDENTITY VERIFICATION",
		identity_verification_explanation: "To activate your SIM card, you must verify your identity",
		identity_verification_under_review: "Your passport has been uploaded, we will verify your information within 48 hours",
		upload_passport: "Upload passport ID page",
		uploaded_passport: "Uploaded Passport",
		terms_of_service_heading: "TERMS OF SERVICE",
		click_to_view: "Click to view",
		terms_of_service: "Terms of Service",
		click_to_agree: "Click to Agree",
		already_agreed: "You have agreed to the Terms of Service",
		delivery_info_heading: "SIM DELIVERY INFO",
		referrals_heading: "REFERRAL BONUSES",
		your_referral_code: "Your unique referral code",
		one_month_free: "When a friend you refer signs up for the 12-month plan, you both get $20 off the next payment",
		up_to_two: "(Bonuses credited to account for next payment, up to $80 off 12-month plan)",
		your_referrals: "Your Referrals",
		no_referrals_yet: "None",
		missing_referrals: "Don't see all your referrals? ",
		status: "Status",
		login_to_view: "Please log in to view your profile"
	},
	'zh': {
		already_have_plan: "此账户已选择电话服务，正在帮您跳转页面。如10秒钟内无自动跳转，请点击右上用户图标，前往账户信息。",
		plan_details_heading: "填写信息",
		payment_heading: "付款",
		plan_details: "电话服务细节",
		everywhere_unlimited: "已选择：「F1 小能手全球无限卡」",
		plan_duration: "选择电话服务套餐",
		cost: "每月价格",
		cost_unit: "美金",
		social_instead: "如想通过Email、微信或 WhatsApp 开通「全球无限卡」服务，",
		contact_us: "请直接联系我们",
		contact: "联系方式",
		sim_user_name: "电话卡使用者姓名",
		wechat_username: "微信用户名",
		whatsapp_phone_number: "WhatsApp 电话号码",
		preferred_contact_method: "首选联系方式",
		wechat: "微信",

		student_info: "学生信息",
		school_name: "学校名称",
		degree_type: "学位类别",

		existing_number: "现有美国电话号码",
		existing_number_blurb: "如希望继续使用现有美国电话号码，请填写以下信息",
		current_us_number: "现有美国电话号码",
		current_us_carrier: "现有美国电话运营商",

		delivery: "SIM 卡物流信息",
		delivery_timeframe: "SIM卡送货日期",
		delivery_timeframe_2: "SIM卡送货时间段：",
		delivery_window_1: "SIM卡将于此日期前后",
		delivery_window_2: "日送达",
		delivery_country: "配送国家",
		plan_activation_date: "电话卡激活日期",
		plan_activation_date_2: "电话卡激活日期：",
		plan_activation_date_start: "需在SIM卡送货之后",
		plan_must_be_activated_in_us: "电话卡激活时学生必须在美国境内。如首次插入电话卡时在美国境外以后将无法激活电话卡",
		delivery_contact_name: "收货人姓名",
		delivery_contact_phone: "收货人电话",
		delivery_address: "收货地址",
		delivery_notes: "收货备注",
		to: "至",

		review_order: "确认订单信息",
		edit_plan_details: "编辑电话服务信息",

		confirm_delivery_info: "确认SIM卡物流信息",
		change_delivery_details: "修改物流信息",

		payment: "付款",
		phone_service_cost: "电话服务费（含税）",
		taxes: "销售税",
		month: " 个月",
		total_cost: "总金额",
		usd: " 美金",
		usd_per_month: "美金/月",
		promo_code: "优惠码",
		apply: "使用优惠",
		discounted_price: "折后价格",

		has_payment_error: "出现故障，未支付成功。请重新付款或",
		contact_us_short: "联系我们",

		// profile page specific
		no_current_plan_header: "「全球无限卡」可靠的信号、贴心的服务",
		no_current_plan_description: "每月低达 $45.99 美金，通过优惠每年最多可免2个月话费",
		no_current_plan_link: "选择「全球无限卡」服务",
		pending_payment_header: "现在付款，即刻开通「全球无限卡」服务",
		pending_payment_description: "可靠的信号、贴心的服务 -- 你值得拥有",
		pending_payment_link: "前往付款",
		sim_scheduled: "您的 SIM 卡将于 ",
		and: " 与 ",
		sim_scheduled_2: " 之间抵达以下显示的地址",
		sim_in_mail: "您的 SIM 卡已寄出！ 物流追踪码: ",
		all_set: "SIM卡已成功送达! 可通过",
		activate_contact: " Email、微信或 WhatsApp 信息",
		activate_plan_after: " 于 ",
		activate_plan_after_2: " 或以后激活您的SIM卡",
		billing_will_start: "（注意：必须人卡都在美国境内时才可以激活）",
		plan_is_active: "已开通「无限卡」服务",
		your_plan: "已选择「全球无限卡」服务",
		your_phone_number: "您的电话号码",
		account_changes: "有问题或需修改信息？",
		contact_us_capitalized: "请联系我们",
		sim_card_number: "SIM卡号",
		payment_confirmation: "付款号",
		identity_verification: "身份认证",
		pending: "尚未认证",
		completed: "完成认证",
		request_refund: "申请退款",
		identity_verification_heading: "身份认证",
		identity_verification_explanation: "激活 SIM 卡之前必须先完成身份验证",
		identity_verification_under_review: "您的护照已成功上传，我们会在48小时内为您完成身份验证",
		upload_passport: "上传护照信息页照片",
		uploaded_passport: "已上传护照",
		terms_of_service_heading: "服务条款",
		click_to_view: "点击链接查看",
		terms_of_service: "服务条款",
		click_to_agree: "点击接受服务条款",
		already_agreed: "您已选择接受服务条款",
		delivery_info_heading: "SIM 卡物流信息",
		referrals_heading: "邀请码优惠",
		your_referral_code: "您的推荐号",
		one_month_free: "推荐朋友购买「全球无限卡12个月」套餐服务，二人均可获得 $20 话费",
		up_to_two: "(获减话费可于下一次付款时使用，每年免话费上限为 $80)",
		your_referrals: "您推荐的朋友",
		no_referrals_yet: "暂无",
		missing_referrals: "您推荐的朋友以上没有显示? ",
		status: "电话服务状态",
		login_to_view: "请登录查看账户信息"
		
	}
}

export { planSignupTranslations };