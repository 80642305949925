const choosePlanTranslations = {
	'en': {
		starting: "STARTING",
		per_month: "USD/month + taxes",
		unlimited_summary: "Unlimited 5G/4G LTE data, call, and text",
		wechat_summary: "Wechat & WhatsApp helpdesk - never wait on the phone", 
		alipay_summary: "UnionPay + Alipay accepted",
		sim_delivery_summary: "Free SIM card delivery to Mainland China and Hong Kong",
		no_ssn_summary: "No US SSN needed to activate plan",
		see_plan_details: "See plan details",
		compare: "Compare against other popular plans",
		select_duration: "Service Duration",
		or: "or",
		signup_with_wechat: "Sign up directly through Email, Wechat, or Whatsapp",
		
		the_plan: "THE PLAN",
		the_plan_blurb: "The best coverage in the U.S. that's got you covered everywhere, always",
		unlimited_calls: "Unlimited calls and texts",
		unlimited_data: "Unlimited highspeed 5G/4G LTE",
		official_partner: "Official Verizon partner, directly providing top Verizon plans at a lower price with better services",
		fastest_speed: "Top-tier median data speeds in the country, provided directly by Verizon",


		dedicated_service: "DEDICATED SERVICE",
		dedicated_service_blurb: "Our IM-based concierge service means that you'll never need to go into a store or wait over the phone",
		sim_delivery: "Free SIM card delivery to your home in Mainland China and Hong Kong, or to an address in the US",
		chinese_service: "Helpdesk service in Chinese & English",
		multichannel_service: "Multi-channel servicing via Wechat, WhatsApp, and text",
		urgent_replacement: "Urgent sim and device replacement at discounted rates",
		sms_reminders: "International student-specific SMS reminders for key events and deadlines (eg. OPT, tax filing)",
		
		simple_process: "SIMPLE, SECURE, PROCESS",
		simple_process_blurb: "Avoid US SSN and credit card requirements and save on additional activation fees",
		alipay: "Alipay compatiable",
		no_ssn: "Refunds can be requested after 2 months on any plan",
		no_fees: "No activation fees, no sim card fees ($15-$50 for most US telcom providers)",
		no_surcharge: "No miscellaneous usage surcharges",
		personal_information: "Zero personal information sharing with third-party apps",
		secure_transactions: "All transactions powered and secured by Stripe and Alipay. Our system do not have access to any of your credit card and payment information"

	},
	'zh': {
		starting: "每月价格",
		per_month: "美金起 + 税",
		unlimited_summary: "无限 5G/4G LTE 流量、通话、短信",
		wechat_summary: "微信与 WhatsApp 中英文双语服务，无需在电话线上等候",
		alipay_summary: "支持银联、支付宝付款",
		sim_delivery_summary: "新电话卡可免费送至中国大陆与香港",
		no_ssn_summary: "开通无需美国SSN社会安全卡、美国信用卡",
		see_plan_details: "查看电话服务细节",
		compare: "同类留学生常有电话服务对比",
		select_duration: "「无限卡」套餐周期",
		or: "或",
		signup_with_wechat: "通过 Email、微信或 WhatsApp直接开通服务",
		
		the_plan: "F1「无限卡」套餐",
		the_plan_blurb: "全美最广信号覆盖",
		unlimited_calls: "美国无限通话与短信",
		unlimited_data: "无限高速 5G/4G LTE 流量",
		official_partner: "美国大电信商 Verizon 官方合作伙伴，为您带来性价比极高的服务",
		fastest_speed: "Verizon 高速流量，平均速度领先 T-Mobile 等大电信商，远超中美一卡双号及美国第三方MVNO服务",

		dedicated_service: "贴心服务",
		dedicated_service_blurb: "开通无需美国SSN社会安全码、美国信用卡，海外省心、家人放心",
		sim_delivery: "新电话卡可免费送至中国大陆与香港或美国地址，落地美国即可开通",
		chinese_service: "中英文双语可靠线上服务，无需亲临实体店、无需在电话线上等候电讯商解答疑难",
		multichannel_service: "可通过Email、微信、WhatsApp 等多种渠道咨询、开通账户、售后咨询",
		urgent_replacement: "紧急补卡、补手机服务",
		sms_reminders: "留学生活重要事件前短信提醒，如OPT表格与报税等事项",

		simple_process: "安全付款",
		simple_process_blurb: "付款路径安全、不满意可退款",
		alipay: "支持多种信用卡及支付宝结算",
		no_ssn: "两个月后如不满意可申请退款",
		no_fees: "无 activation 开通费、SIM 卡费等附加费用（大多数美国电讯公司会收取 $15 - $50 美金开通费)",
		no_surcharge: "无增收费",
		personal_information: "「F1 小能手」不会与任何第三方软件或公司分享您的个人信息",
		secure_transactions: "收付款通过 Stripe 和支付宝科技快捷并安全，「F1 小能手」的平台不接触您的信用卡付款信息",		
	}
}

export { choosePlanTranslations };