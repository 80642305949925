const homepageTranslations = {
	'en': {
		getStarted: "Unlimited service starting $45.99 USD",
		getStartedButton: "Get Started Now",
		or: "or",
		getStartedWithSocial: "Get started via Email, WeChat, or WhatsApp"
	},
	'zh': {
		or: "或",
		getStarted: "「F1 无限卡」服务每月$45.99美金起",
		getStartedButton: "前往服务介绍",
		getStartedWithSocial: "通过 Email、微信、或 WhatsApp 轻松开通"
	}
}

export { homepageTranslations };