const TAX_RATE = 0.08;

const MONTHLY_COST_12M = 45.99;
const MONTHLY_COST_6M = 50.99;
const MONTHLY_COST_2M = 55.99;


const FAF_VERIZON_UNLIMITED_START = 40.00;
const FAF_VERIZON_UNLIMITED_PLUS = 50.00;
const FAF_TMOBILE_UNLIMITED_FAMILY = 30.00;


// Promo Codes


// THIS IS $20 OFF ONLY FOR NOW, NOT FULL MONTH
const PROMO_20OFF = [
	"AMAZING20",// Mom's code
	"HBJ20",// Tracy Sun
	"USC20",// Xiao Ya
	"PENNHKSA",// UPenn HKSA, point person = Selina Li
	"JENNY20",// Jenny from Emory group chat
	"UCHICAGO20",// Uchicago OIA
	"CMU20",// CMU code given to jeff ho
];

const USER_REFERRAL_CODES = [
	"MARISSAPANG",
	"MARKUSMAK",
	"GUTIANSUI",
	"SHAOSHUAI",
	"WANGZHOUYI",
	"QIANQIYUAN",
	"CASSIELIN",
	"JACKSONCHONG",
];

const OFF20 = PROMO_20OFF.concat(USER_REFERRAL_CODES);

const EXPIRED = [
	"FAF0"
];

const TEST_CODES = [
	"TEST_DONOTUSE"
];

export {
	TAX_RATE,
	MONTHLY_COST_12M, MONTHLY_COST_6M, MONTHLY_COST_2M,
	FAF_VERIZON_UNLIMITED_START, FAF_VERIZON_UNLIMITED_PLUS, FAF_TMOBILE_UNLIMITED_FAMILY,
	TEST_CODES,
	USER_REFERRAL_CODES,
	OFF20,
	EXPIRED
};