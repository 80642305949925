import React from 'react';

import MailIcon from '../images/mail_icon.png';
import WechatIcon from '../images/wechat_icon.jpg';
import WhatsappIcon from '../images/whatsapp_icon.png';

import './SocialIcons.scss';

export default function SocialIcons(){
	return (
		<div className="icon-box">
    		<img className="social-icon" src={MailIcon} alt="mail_icon"/>
    		<img className="social-icon" src={WechatIcon} alt="wechat_icon"/>
    		<img className="social-icon" src={WhatsappIcon} alt="whatsapp_icon"/>
    	</div>
	);
}