import React, {useState, useEffect} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useAppContext } from '../../libs/contextLib';
import { 
	getPlanCost, calculatePlanExpirationDate, calculateDeliveryWindowEnd,
	getSocialName, getDegreeTypeName, getDeliveryCountryName, 
	translatePromoCodeError,
} from '../../libs/calculationLib';

import { 
	calculateCost, calculatePromoCodeErrorMessage
} from '../../libs/billingLib';
import { useCookies } from 'react-cookie';

import { Elements, StripeProvider, CardElement, injectStripe } from "react-stripe-elements";
import AlipayButton from '../../components/AlipayButton';
import SupportedCards from '../../images/supported_cards.png';
import config from "../../config";

import {
	PLAN_2M, PLAN_6M, PLAN_12M,
	PAYMENT_METHOD_CARD, PAYMENT_METHOD_ALIPAY,
	PLAN_STATUS_OPTIONS, 
	ORDER_TO_SIM_DELIVERY_BUFFER_DAYS, DELIVERY_START_TO_ACTIVATION_BUFFER_DAYS,
	LATEST_POSSIBLE_START_DATE,
	ALIPAY_SUCCEEDED, ALIPAY_FAILED,
} from '../../GLOBAL_VARIABLES';

import {
	MONTHLY_COST_2M, MONTHLY_COST_6M, MONTHLY_COST_12M,
} from '../../FRONTEND_BILLING_VARIABLES';

import { 
	addDaysToStringDate,
	stringDateToEnglish, 
	jsDateToStringDate 
} from '../../libs/formatLib';


import { Form, FormInput, Button } from "shards-react";
import LoaderButton from '../../components/LoaderButton';

import { nanoid } from 'nanoid'

import { API } from 'aws-amplify';

import './FriendsSignup.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'

import ReactGA from 'react-ga';

export default function FriendsSignup(props){
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	
	const history = useHistory();
	const class_name = props.size;

	const { 
		isAuthenticated, setShowAuthModal, language, 
		hasPaymentError, setHasPaymentError
	} = useAppContext();
	const [cookiesEmail, ,] = useCookies(['user_email']);
	const [cookiesIdentityID, ,] = useCookies(['user_identity_id']);

	// logistics state variables
	const [stripe, setStripe] = useState(null);
	const [inPaymentMode, setInPaymentMode] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pageIsLoading, setPageIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [errorMessages, setErrorMessages] = useState(null);
	const [promoCode, setPromoCode ] = useState("NONE");
	const [promoCodeError, setPromoCodeError] = useState("NONE");
	const [hasPromoCodeError, setHasPromoCodeError] = useState(false);
	const [redirecting, setRedirecting] = useState(false);

	const queryString = require('query-string');
	const plan_selected = queryString.parse(useLocation().search).plan;
	const alipay_payment_intent = queryString.parse(useLocation().search).payment_intent;
	const alipay_payment_intent_client_secret = queryString.parse(useLocation().search).payment_intent_client_secret;
	const alipay_redirect_status = queryString.parse(useLocation().search).redirect_status;

	// make state variables
	const [planSelected, setPlanSelected] = useState(plan_selected || PLAN_12M);
	const [simUserName, setSimUserName] = useState("");
	const [contactEmail, setContactEmail]=useState("");
	const [contactWechat, setContactWechat]=useState("");
	const [contactWhatsapp, setContactWhatsapp]=useState("");
	const [preferredContactMethod, setPreferredContactMethod]=useState("email");
	const [schoolName, setSchoolName]=useState("");
	const [degreeType, setDegreeType]=useState("bachelors");
	const [planActivationDate, setPlanActivationDate]=useState("");
	const [deliveryCountry, setDeliveryCountry]=useState("china");
	const [deliveryTimeframe, setDeliveryTimeframe]=useState("");
	const [deliveryContactName, setDeliveryContactName]=useState("");
	const [deliveryContactPhone, setDeliveryContactPhone]=useState("");
	const [deliveryAddress, setDeliveryAddress]=useState("");
	const [deliveryNotes, setDeliveryNotes]=useState("");
	const [existingUSNumber, setExistingUSNumber] = useState("");
	const [existingUSCarrier, setExistingUSCarrier] = useState("");

	// alipay state variables
	const [ paymentMethod, setPaymentMethod ] = useState(PAYMENT_METHOD_CARD)
	const [ alipayPaymentIntent, setAlipayPaymentIntent ]  = useState(alipay_payment_intent || "NONE");
	const [ alipayPaymentIntentClientSecret, setAlipayPaymentIntentClientSecret ]  = useState(alipay_payment_intent_client_secret || "NONE");
	const [ alipayRedirectStatus, setAlipayRedirectStatus ] = useState(alipay_redirect_status || "NONE");
	
	// dates
	const earliest_sim_delivery_date = addDaysToStringDate(
		jsDateToStringDate(new Date()), // today's date in string form
		ORDER_TO_SIM_DELIVERY_BUFFER_DAYS
	);

	const earliest_plan_activation_date = addDaysToStringDate(
		earliest_sim_delivery_date, // start of delivery window
		DELIVERY_START_TO_ACTIVATION_BUFFER_DAYS
	);
	const [ earliestSimDeliveryDate, setEarliestSimDeliveryDate] = useState(earliest_sim_delivery_date);
	const [ earliestPlanActivationDate, setEarliestPlanActivationDate ] = useState(earliest_plan_activation_date);

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		await setStripe(window.Stripe(config.STRIPE_KEY));

		if (!isAuthenticated){ // log in if user has not authenticated
			setShowAuthModal(true);
			setPageIsLoading(false);
		} else {
			// get existing data
			var data = await API.get(
				'f1plans-api', '/f1plans-api/'+cookiesIdentityID.user_identity_id
			)

			if (Object.keys(data).length > 0){ // if existing plan info exists
				// if payment has been made, redirect
				if (data.pending_payment === "NO"){
					setRedirecting(true);
					await setTimeout(() => history.push('/profile'), 3500)
				} else {
					var date_changed = false;
					
					if (data.delivery_timeframe < earliestSimDeliveryDate){
						data['delivery_timeframe'] = earliestSimDeliveryDate
						date_changed = true;
					}

					// calculate earliest plan activation date
					const earliest_plan_activation_date = addDaysToStringDate(
						data.delivery_timeframe, // start of delivery window
						DELIVERY_START_TO_ACTIVATION_BUFFER_DAYS
					);
					setEarliestPlanActivationDate(earliest_plan_activation_date)
					if (data.plan_activation_date < earliestPlanActivationDate){
						data['plan_activation_date'] = earliestPlanActivationDate
						date_changed = true;	
					}

					// ping server to update if activation date data has changed
					if (date_changed){
						await API.put(
							"f1plans-api", 
							'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
							{ body: data }
						)
					}
					
					// set signup form input when data already exists
					setInPaymentMode(true); // 
					setPlanSelected(data.plan_selected)
					setSimUserName(data.sim_user_name)
					setContactEmail(data.contact_email)

					if (data.contact_wechat !== "NONE"){
						setContactWechat(data.contact_wechat)
					}

					if (data.contact_whatsapp !== "NONE"){
						setContactWhatsapp(data.contact_whatsapp)
					}
					setPreferredContactMethod(data.preferred_contact_method)
					setSchoolName(data.school_name)
					setDegreeType(data.degree_type)
					setPlanActivationDate(data.plan_activation_date)
					setDeliveryCountry(data.delivery_country)
					setDeliveryTimeframe(data.delivery_timeframe)
					setDeliveryContactName(data.delivery_contact_name)
					setDeliveryContactPhone(data.delivery_contact_phone)
					setDeliveryAddress(data.delivery_address)
					setExistingUSNumber(data.existing_us_number)
					setExistingUSCarrier(data.existing_us_carrier)

					if (data.delivery_notes !== "NONE"){
						setDeliveryNotes(data.delivery_notes)
					} else {
						setDeliveryNotes("")
					}
					setPaymentMethod(data.payment_method)


					// if this is a re-direct after Alipay, and all info is in place
					if (alipayPaymentIntentClientSecret !== "NONE"){
						// redirect after successful payment
						if (alipay_redirect_status === ALIPAY_SUCCEEDED){
							// ping api to update payment info
							var payload = data
							// payload['payment_id'] = alipay_payment_intent
							payload['pending_payment'] = "NO"
							payload['alipay_payment_intent'] = alipay_payment_intent
							payload['alipay_payment_intent_client_secret'] = alipay_payment_intent_client_secret

							await API.put(
								"f1plans-api", 
								'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
								{ body: payload }
							)

							setPageIsLoading(false);
							// TODO: show success message
							history.push('/profile');
						} else if (alipay_redirect_status === ALIPAY_FAILED){ // payment status is not success

							// update DB to remove payment info
							var payload = data
							payload['payment_id'] = "NONE"
							payload['alipay_payment_intent'] = "NONE"
							payload['alipay_payment_intent_client_secret'] = "NONE"
							payload['payment_method'] = PAYMENT_METHOD_CARD

							await API.put(
								"f1plans-api", 
								'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
								{ body: payload }
							)

							// reload page with no query parameters
							history.push('/choose/plan-signup')

							// show error: you have not been charged, try again or contact us
							setHasPaymentError(true);
						}
					// if not a re-direct, we want to populate our client secret if 
					//  one exists on dynamo DB (i.e. user clicked Alipay check out
					// but never scanned code)
					} else { 
						// set state variables for future use
						if (data.alipay_payment_intent_client_secret !== "NONE"){
							setAlipayPaymentIntentClientSecret(data.alipay_payment_intent_client_secret);
							setAlipayPaymentIntent(data.alipay_payment_intent);
						}
					}
				}
			}
		}
		setPageIsLoading(false);
	}

	const switchToPayment = () => setInPaymentMode(true);
	const switchToPlanInfo = () => setInPaymentMode(false);

	function validateFormAtSubmit(){
		const MANDATORY_FIELD = language === "zh" ? "必填项目" : "Mandatory field"
		var errors = {}
		var error_bool = false;

		if (simUserName.length === 0){
			error_bool = true;
			errors['sim_user_name']=MANDATORY_FIELD;
		}

		if (contactEmail.length === 0){
			error_bool = true;
			errors['contact_email']=MANDATORY_FIELD;
		}

		if (contactWechat.length === 0 && preferredContactMethod === "wechat"){
			error_bool = true;
			errors['contact_wechat']= language === "zh" ? 
				"您已选择微信为您的首选联系方式。请填写微信账户信息"
				:
				"Must fill out Wechat if it is the selected preferred contact method"
		}

		if (contactWhatsapp.length === 0 && preferredContactMethod === "whatsapp"){
			error_bool = true;
			errors['contact_whatsapp'] = language === "zh" ? 
				"您已选择 WhatsApp 为您的首选联系方式。请填写WhatsApp 账户信息"
				:
				"Must fill out WhatsApp if it is the selected preferred contact method"

		}

		if (schoolName.length === 0){
			error_bool = true;
			errors['school_name']=MANDATORY_FIELD;
		}

		if (deliveryCountry.length === 0){
			error_bool = true;
			errors['delivery_country']=MANDATORY_FIELD;
		}

		if (deliveryTimeframe.length === 0){
			error_bool = true;
			errors['delivery_timeframe']=MANDATORY_FIELD;
		}
		
		if (planActivationDate.length === 0){
			error_bool = true;
			errors['plan_activation_date']=MANDATORY_FIELD;
		} 

		if (deliveryContactName.length === 0){
			error_bool = true;
			errors['delivery_contact_name']=MANDATORY_FIELD;
		}

		if (deliveryContactPhone.length === 0){
			error_bool = true;
			errors['delivery_contact_phone']=MANDATORY_FIELD;
		}
		if (deliveryAddress.length === 0){
			error_bool = true;
			errors['delivery_address']=MANDATORY_FIELD;
		}

    	return {has_error: error_bool, error_messages: errors};
  	}

  	function validateInfo(){
  		return true;
  	}

	async function uploadSignupForm(e){
		e.preventDefault();
		await setErrorMessages(null);
		var {has_error, error_messages} = await validateFormAtSubmit();

		if (has_error){
			setHasError(true);
			setErrorMessages(error_messages);
			return
		}

		setIsLoading(true);
		var payload = {
			account_email: cookiesEmail.user_email,
			sim_user_name: simUserName,
			contact_email: contactEmail,
			contact_wechat: contactWechat,
			contact_whatsapp: contactWhatsapp,
			preferred_contact_method: preferredContactMethod,
			school_name: schoolName,
			degree_type: degreeType,
			delivery_timeframe: deliveryTimeframe, 
			delivery_country: deliveryCountry,
			delivery_contact_name: deliveryContactName,
			delivery_contact_phone: deliveryContactPhone,
			delivery_address: deliveryAddress,
			delivery_notes: deliveryNotes === "" ? "NONE" : deliveryNotes,
			plan_selected: planSelected,
			plan_activation_date: planActivationDate,
			plan_expiration_date: calculatePlanExpirationDate(planActivationDate, planSelected),
			pending_payment: "YES",
			payment_method: PAYMENT_METHOD_CARD,
			payment_id: "NONE",
			promo_code: "NONE",
			alipay_payment_intent: "NONE",
			alipay_payment_intent_client_secret: "NONE",
			delivery_tracking: {
				sent: "NO", received: "NO", tracking_number: ""
			},
			existing_us_number: existingUSNumber === "" ? "NONE" : existingUSNumber,
			existing_us_carrier: existingUSCarrier === "" ? "NONE" : existingUSCarrier,
			plan_active: "NO", 
			plan_expired: "NO",
			referral_code: "NONE",
			referrals_tracking: [],
			phone_number: "NONE",
			sim_card_number: "NONE",
			passport_file_key: "NONE",
			passport_verified: "NO",
			signed_terms_and_conditions: "NO",
			signed_terms_and_condtions_date: "NONE",
			account_creation_method: "WEBSITE",
			admin_action_required: "YES",
			admin_action_required_details: "New plan signup form submitted.",
			admin_notes: "NONE",
			user_action_history: ["NONE"],
			last_updated: Date.now()
		}

		const data = await API.get('f1plans-api', '/f1plans-api/'+cookiesIdentityID.user_identity_id)


		if (Object.keys(data).length > 0){

			// this just needs to be whatever the current data says
			payload['admin_notes'] = data.admin_notes == null ? "NONE" : data.admin_notes
			payload['user_action_history'] = data.user_action_history; 

			// update
			await API.put(
				"f1plans-api", 
				'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
				{ body: payload }
			)
		} else {
			payload['user_action_history'] = ["Initial entry for user created"]; 
			payload['admin_action_required'] = "YES"
			payload['admin_action_required_details'] = "User updated plan signup information"
			await API.post("f1plans-api", "/f1plans-api", { body: payload })
		}

		setIsLoading(false);
		setInPaymentMode(true);
	}

	function handleDateChange(e){

		const earliest_sim_delivery_date = addDaysToStringDate(
			jsDateToStringDate(new Date()), // today's date in string form
			ORDER_TO_SIM_DELIVERY_BUFFER_DAYS
		)

		if (e.target.id === "delivery_timeframe"){

			if (e.target.value < earliest_sim_delivery_date){
				var errors = errorMessages
				errors['delivery_timeframe'] = language === "zh" ? 
					"请选择 "+earliest_sim_delivery_date+" 之后的日期"
					:
					"Please select a delivery date on or after: "+stringDateToEnglish(earliest_sim_delivery_date);

				setHasError(true);
				setErrorMessages(errors);
				return
			}

			if (e.target.value > LATEST_POSSIBLE_START_DATE){
				var errors = errorMessages
				errors['delivery_timeframe'] = language === "zh" ? 
					"电话开通时间必须早于2022年6月1日"
					:
					"Sorry, right now you can only sign up for services starting before June 1, 2022";
				
				setHasError(true);
				setErrorMessages(errors);
				return
			}

			// if no errors, set delivery timeline and reset earliest plan activation date
			setDeliveryTimeframe(e.target.value)
			const earliest_plan_activation_date = addDaysToStringDate(
				e.target.value, // start of delivery window
				DELIVERY_START_TO_ACTIVATION_BUFFER_DAYS
			);
			setEarliestPlanActivationDate(earliest_plan_activation_date)

			if (planActivationDate < earliest_plan_activation_date){
				setPlanActivationDate(earliest_plan_activation_date)
			}
		} else if (e.target.id === "plan_activation_date"){
			const earliest_plan_activation_date = addDaysToStringDate(
				deliveryTimeframe, // start of delivery window
				DELIVERY_START_TO_ACTIVATION_BUFFER_DAYS
			);

			if (e.target.value < earliest_plan_activation_date){
				var errors = errorMessages
				errors['plan_activation_date'] = language === "zh" ? 
					"请选择 "+earliest_plan_activation_date+" 之后的日期"
					:
					"Please select a delivery date on or after: "+stringDateToEnglish(earliest_plan_activation_date);
				
				setHasError(true);
				setErrorMessages(errors);
				return
			}

			if (e.target.value > LATEST_POSSIBLE_START_DATE){
				var errors = errorMessages
				errors['plan_activation_date'] = language === "zh" ? 
					"电话开通时间必须早于2022年6月1日"
					:
					"Sorry, right now you can only sign up for services starting before June 1, 2022";
				
				setHasError(true);
				setErrorMessages(errors);
				return
			}
			setPlanActivationDate(e.target.value)
		}
	}

	function handleFormChange(e){
		if (e.target.id === "plan_selected"){
			setPlanSelected(e.target.value)
		} else if (e.target.id === "sim_user_name"){
			setSimUserName(e.target.value)
		} else if (e.target.id === "contact_email"){
			setContactEmail(e.target.value)
		} else if (e.target.id === "contact_wechat"){
			setContactWechat(e.target.value)
		} else if (e.target.id === "contact_whatsapp"){
			setContactWhatsapp(e.target.value)
		} else if (e.target.id === "preferred_contact_method"){
			setPreferredContactMethod(e.target.value)
		} else if (e.target.id === "school_name"){
			setSchoolName(e.target.value)
		} else if (e.target.id === "degree_type"){
			setDegreeType(e.target.value)
		} else if (e.target.id === "delivery_country"){
			setDeliveryCountry(e.target.value)
		} else if (e.target.id === "delivery_contact_name"){
			setDeliveryContactName(e.target.value)
		} else if (e.target.id === "delivery_contact_phone"){
			setDeliveryContactPhone(e.target.value)
		} else if (e.target.id === "delivery_address"){
			setDeliveryAddress(e.target.value)
		} else if (e.target.id === "delivery_notes"){
			setDeliveryNotes(e.target.value)
		} else if (e.target.id === "existing_us_number"){
			setExistingUSNumber(e.target.value)
		} else if (e.target.id === "existing_us_carrier"){
			setExistingUSCarrier(e.target.value)
		}
	}

	function applyPromoCode(e){
		e.preventDefault();
		setPromoCode(e.target.value);
		const promo_error = calculatePromoCodeErrorMessage(planSelected, e.target.value);
		
		if (promo_error !== "NONE"){
			setHasPromoCodeError(true);
			setPromoCodeError(promo_error);
		} else {
			setHasPromoCodeError(false);
			setPromoCodeError("NONE");
		}
	}

	

	async function initiateAlipay(e){
		e.preventDefault();

		setIsLoading(true);

		try {
			// create new payment intent
			const payment_id = nanoid(8);
			const payment_intent =  await API.post(
	      		"f1plans-api", "/alipayintent", 
			    { body: 
			    	{ 
			    		plan_selected: planSelected, 
			    		promo_code: promoCode,
			    		receipt_email: cookiesEmail.user_email,
			    		payment_id: payment_id
			    	}
			    }
			);

		    // ping API to update payment intent key, and ID
		    const curr_data = await API.get('f1plans-api', '/f1plans-api/'+cookiesIdentityID.user_identity_id)
		    var payload = curr_data;
		    payload['alipay_payment_intent'] = payment_intent.id
		    payload['alipay_payment_intent_client_secret'] = payment_intent.client_secret
		    payload['payment_id'] = payment_id
		    payload['payment_method'] = PAYMENT_METHOD_ALIPAY
		    payload['promo_code'] = promoCode

		    setAlipayPaymentIntent(payment_intent.id)
		    setAlipayPaymentIntentClientSecret(payment_intent.client_secret)

		    await API.put(
					"f1plans-api", 
					'/f1plans-api/'+cookiesIdentityID.user_identity_id, 
					{ body: payload }
				)

				// redirect to Alipay
				const alipay_res = await stripe.confirmAlipayPayment(
					payment_intent.client_secret, {
		    		return_url: `${window.location.href}`,
		    	}
		    );
		} catch(e){
			// do nothing
			setIsLoading(false);
			setHasPaymentError(true);
		}
	}


	return (
		!pageIsLoading ? 
		<div className="ContentPage PlanSignup">
		    <div className={ class_name }>
		    	{isAuthenticated ?
	    		<div className="SignupInfo">
	    			<div className="SliderBar">
	    				<div className={inPaymentMode ? "SliderComponent Left" : "SliderComponent Selected Left"}>
	    					<FormattedMessage id='plan_details_heading'/>
	    				</div>
	    				<div className={inPaymentMode ? "SliderComponent Selected Right" : "SliderComponent Right"}>
	    					<FormattedMessage id='payment_heading'/>
	    				</div>
	    			</div>
	    			<br/><br/>
	    			{ 
	    			}
	    			{ redirecting && 
	    				<h2 style={{lineHeight:"50px"}}>
	    					<FormattedMessage id="already_have_plan"/>
	    				</h2>
	    			}
	    			{!inPaymentMode && !redirecting &&
	    				<Form onSubmit={uploadSignupForm}>
		    				<div className="PlanOverview">
		    					<br/>
		    					<h4 style={{marginBottom:"10px"}}>
		    						<FormattedMessage id='plan_duration'/>
		    					</h4>
		    					<select className="short-input" id="plan_selected" value={planSelected} onChange={handleFormChange}>
					    			<option value={PLAN_2M}>
					    				{ language === "zh" ? "「无限卡 2 个月」服务（$" + MONTHLY_COST_2M + "美金/月）" : "2M Plan at $" + MONTHLY_COST_2M + " / month" } 
					    			</option>
					    			<option value={PLAN_6M}>
					    				{ language === "zh" ? "「无限卡 6 个月」服务（$" + MONTHLY_COST_6M + "美金/月）" : "6M Plan at $" + MONTHLY_COST_6M + " / month" } 
					    			</option>
					    			<option value={PLAN_12M}>
					    				{ language === "zh" ? "「无限卡12个月」服务（$" + MONTHLY_COST_12M + "美金/月）" : "12M Plan at $" + MONTHLY_COST_12M + " / month" } 
					    			</option>
					    		</select>
					    		<div style={{height: "35px"}}/>
					    		<h5 style={{marginBottom:"-2px"}}>
			    					<FormattedMessage id='delivery_timeframe'/>
				    			</h5>
				    			<span>
				    				<FormattedMessage id='delivery_window'/>
				    			</span>
				    			<div style={{height: "7px"}}/>
					    		<input className="short-input"
					    			id="delivery_timeframe" 
					    			value={deliveryTimeframe} 
					    			onChange={handleDateChange} type="date"
					    			min={earliestSimDeliveryDate} max={LATEST_POSSIBLE_START_DATE}
					    		/> 
					    		<div className="emphasized-blue">
					    			{ language === "zh" ? 
					    				"送卡日期需在 " + earliestSimDeliveryDate + "或以后"
					    			: 
					    				"SIM delivery date must be on or after " + jsDateToStringDate(earliestSimDeliveryDate)
					    			}
					    		</div>
					    		<div className="error-message">
			    					{ errorMessages ? errorMessages['delivery_timeframe'] : ""}
			    				</div>
			    				<br/>

					    		<h5 style={{marginBottom: "-2px"}}>
					    			<FormattedMessage id='plan_activation_date'/>
					    		</h5>
					    		<span>
				    				<FormattedMessage id='plan_activation_date_start'/>
				    			</span>
				    			<div style={{height: "7px"}}/>
					    		<input className="short-input" 
					    			id="plan_activation_date" value={planActivationDate} 
					    			onChange={handleDateChange} type="date"
					    			min={earliestPlanActivationDate} max={LATEST_POSSIBLE_START_DATE}
					    		/>
					    		<div className="emphasized-blue">
					    			{ language === "zh" ? 
					    				"激活日期需在 " + earliestPlanActivationDate + "或以后"
					    			: 
					    				"Activation must be on or after " + jsDateToStringDate(earliestPlanActivationDate)
					    			}
					    		</div>
					    		<span className="emphasized-orange">
					    			<FormattedMessage id='plan_must_be_activated_in_us'/>
					    		</span>
					    		<div className="error-message">
			    					{ errorMessages ? errorMessages['plan_activation_date'] : ""}
			    				</div>
					    		<br/><br/>

					    		<span className="bold-text">
					    			<FormattedMessage id='social_instead'/>
					    			&nbsp;
					    			<a target="_blank" href="/contact">
					    				<FormattedMessage id='contact_us'/>
					    			</a>
					    		</span>
					    		<br/><br/>
		    				</div>

			    			<br/><br/><br/>
			    			<h4><FormattedMessage id='contact'/></h4>
			    			<span className="input-label">
			    				<FormattedMessage id='sim_user_name'/>*
			    			</span>
			    			{ language === "zh" ? 
			    				<input 
			    					id="sim_user_name" value={simUserName} 
			    					placeholder={"注意：需与护照上姓名相同，开通前需做身份验证"} 
			    					onChange={handleFormChange} type="text"
			    				/>
			    				:
			    				<input 
			    					id="sim_user_name" value={simUserName} 
			    					placeholder={"As shown on passport, will need verification later."} 
			    					onChange={handleFormChange} type="text"
			    				/>
			    			}
		    				<div className="error-message">
		    					{ errorMessages ? errorMessages['sim_user_name'] : ""}
		    				</div>
		    				<br/>
		    				<span className="input-label">Email*</span>
		    				<input id="contact_email" value={contactEmail} onChange={handleFormChange} type="email"/>
		    				<div className="error-message">
		    					{ errorMessages ? errorMessages['contact_email'] : ""}
		    				</div>
		    				<br/>
		    				<span className="input-label">
		    					<FormattedMessage id='wechat_username'/>
		    				</span>
		    				<input id="contact_wechat" value={contactWechat} onChange={handleFormChange} type="text"/>
		    				<div className="error-message">
		    					{ errorMessages ? errorMessages['contact_wechat'] : ""}
		    				</div>
		    				<br/>

		    				<span className="input-label">
		    					<FormattedMessage id='whatsapp_phone_number'/>
		    				</span>
		    				<input id="contact_whatsapp" value={contactWhatsapp} onChange={handleFormChange} type="text"/>
		    				<div className="error-message">
		    					{ errorMessages ? errorMessages['contact_whatsapp'] : ""}
		    				</div>
		    				<br/>

	    					<span className="input-label">
	    						<FormattedMessage id='preferred_contact_method'/>
	    					</span>
	    					<select id="preferred_contact_method" value={preferredContactMethod} onChange={handleFormChange}>
				    			<option value="email">Email</option>
				    			<option value="wechat">{language === "zh" ? "微信" : "Wechat"}</option>
				    			<option value="whatsapp">WhatsApp</option>
				    		</select>


					    	<br/><br/><br/>
			    			<h4><FormattedMessage id='student_info'/></h4>
			    			<span className="input-label">
			    				<FormattedMessage id='school_name'/>*
			    			</span>
			    			<input id="school_name" value={schoolName} onChange={handleFormChange} type="text"/>
			    			<div className="error-message">
		    					{ errorMessages ? errorMessages['school_name'] : ""}
		    				</div>
		    				<br/>
			    			<span className="input-label">
			    				<FormattedMessage id='degree_type'/>
			    			</span> 
	    					<select id="degree_type" value={degreeType} onChange={handleFormChange}>
				    			<option value="bachelors">
				    				{ language === "zh" ? "本科" : "Bachelor's" }
				    			</option>
				    			<option value="masters">
				    				{ language === "zh" ? "研究生" : "Master's" }
				    			</option>
				    			<option value="doctorate">
				    				{ language === "zh" ? "博士" : "Doctorate" }
				    			</option>
				    			<option value="secondary">
				    				{ language === "zh" ? "初高中" : "Secondary" }
				    			</option>
				    			<option value="other">
				    				{ language === "zh" ? "其它" : "Other" }
				    			</option>	
				    		</select>

				    		<br/><br/><br/>
			    			<h4 style={{marginBottom:"2px"}}><FormattedMessage id='existing_number'/></h4>
			    			<div>
			    				<FormattedMessage id="existing_number_blurb"/>
			    			</div>
			    			<br/>

			    			<span className="input-label">
				    			<FormattedMessage id='current_us_number'/>
				    		</span>
				    		<input type="text" id="existing_us_number" value={existingUSNumber} onChange={handleFormChange}/>
		    				<br/><br/>

				    		<span className="input-label">
				    			<FormattedMessage id='current_us_carrier'/>
				    		</span>
				    		<input type="text" id="existing_us_carrier" value={existingUSCarrier} onChange={handleFormChange}/>

				    		<br/><br/><br/>
			    			<h4><FormattedMessage id='delivery'/></h4>

			    			<span className="input-label">
			    			<FormattedMessage id='delivery_country'/>*
			    			</span>
			    			<select id="delivery_country" value={deliveryCountry} onChange={handleFormChange}>
				    			<option value="china">
				    				{ language === "zh" ? "中国大陆" : "Mainland China" }
				    			</option>
				    			<option value="hk">
				    				{ language === "zh" ? "香港" : "Hong Kong" }
				    			</option>
				    			<option value="us">
				    				{ language === "zh" ? "美国" : "United States" }
				    			</option>
				    		</select>
				    		<br/><br/>			    		

				    		

				    		<span className="input-label">
				    			<FormattedMessage id='delivery_contact_name'/>*
				    		</span>
				    		<input id="delivery_contact_name" value={deliveryContactName} onChange={handleFormChange} type="text"/>
				    		<div className="error-message">
		    					{ errorMessages ? errorMessages['delivery_contact_name'] : ""}
		    				</div>
		    				<br/>

				    		<span className="input-label">
				    			<FormattedMessage id='delivery_contact_phone'/>*
				    		</span>
				    		<input id="delivery_contact_phone" value={deliveryContactPhone} onChange={handleFormChange} type="text"/>
				    		<div className="error-message">
		    					{ errorMessages ? errorMessages['delivery_contact_phone'] : ""}
		    				</div>
		    				<br/>

				    		<span className="input-label">
				    			<FormattedMessage id='delivery_address'/>*
				    		</span>
				    		<textarea id="delivery_address" value={deliveryAddress} onChange={handleFormChange}/>
				    		<div className="error-message">
		    					{ errorMessages ? errorMessages['delivery_address'] : ""}
		    				</div>
		    				<br/>

				    		<span className="input-label">
				    			<FormattedMessage id='delivery_notes'/>:
				    		</span>
				    		<textarea id="delivery_notes" value={deliveryNotes} onChange={handleFormChange}/>
				    		<br/><br/><br/><br/>

              	{ errorMessages && 
              		<span className="error-message">
              			{ language === "zh" ? "表格错误，请检查" : "There is a form error, please double check your input" }
              		</span>
              	}
              	{ language === "zh" ? 
	              	<LoaderButton 
	              		type="submit" label="前往支付"
	              		disabled={!validateInfo()} isLoading={isLoading}
	              	/>
	              :
	              	<LoaderButton 
	              		type="submit" label="Proceed To Payment"
	              		disabled={!validateInfo()} isLoading={isLoading}
	              	/>
	              } 
            	</Form>
            }
          	{ inPaymentMode && !redirecting &&
	          	<div>
	          		<h4 style={{"marginBottom": "0"}}>
	          			<FormattedMessage id='review_order'/>
	          		</h4>

	          		<span className="emphasized-blue-link" onClick={switchToPlanInfo}>
	          			<FormattedMessage id='edit_plan_details'/>
	          		</span>
	          		<br/>
	          		{ hasPaymentError &&
		    			<span className="error-message">
		    				<FormattedMessage id='has_payment_error'/> &nbsp;
		    				<a target="_blank" href="/contact">
		    					<FormattedMessage id='contact_us_short'/>
		    				</a>
		    			</span>
		    		}
	          		<br/><br/>
	          		<span className="plan-heading">
	          			{ language === "zh" ? 
	          				"已选择：「全球无限卡" + planSelected.substring(0, planSelected.length-1) + "个月」套餐"
	          				: 
	          				"Plan Selected: Everywhere Unlimited " + planSelected.substring(0, planSelected.length-1) + "-Month Plan"
	          			}
	          		</span>
	          		<br/>	
	          		<span className="bold-text">
	          			{ language === "zh" ? 
	          				"每月价格：$" + getPlanCost(planSelected) + " 美金" 
	          			: 
	          				"Plan Cost: $" + getPlanCost(planSelected) + "/month"
	          			}
	          		</span>

	          		<br/>
	          		<span className="bold-text">
	          			<FormattedMessage id='delivery_timeframe_2'/>
	          		</span>
	          		<span className="bold-text">
	          			{language === "zh" ? 
	          				deliveryTimeframe + "前后7天内" 
	          			: 
	          				stringDateToEnglish(deliveryTimeframe) + " +/- 7 days"
	          			}
	          		</span> 
	          		
	          		<br/>

	          		<span className="bold-text">
	          			<FormattedMessage id='plan_activation_date_2'/>
	          			&nbsp;
	          			{ language === "zh" ? 
	          				planActivationDate
	          			: 
	          				stringDateToEnglish(planActivationDate)
	          			}
	          		</span>

	          		<br/>

	          		<span className="emphasized-orange">
					   	<FormattedMessage id='plan_must_be_activated_in_us'/>
					</span>
					
					<br/><br/>

	          		<span className="input-label medium">
	          			<FormattedMessage id='sim_user_name'/>
	          		</span>
	          		<span className="bold-text">{simUserName}</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			Email
	          		</span>
	          		<span className="bold-text">{contactEmail}</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='wechat_username'/>
	          		</span>
	          		<span className="bold-text">{contactWechat}</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='preferred_contact_method'/>
	          		</span>
	          		<span className="bold-text">
	          			{getSocialName(preferredContactMethod, language)}
	          		</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='school_name'/>
	          		</span>
	          		<span className="bold-text">{schoolName}</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='degree_type'/>
	          		</span>
	          		<span className="bold-text">
	          			{getDegreeTypeName(degreeType, language)}
	          		</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='current_us_number'/>
	          		</span>
	          		<span className="bold-text">
	          			{existingUSNumber}
	          		</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='current_us_carrier'/>
	          		</span>
	          		<span className="bold-text">
	          			{existingUSCarrier}
	          		</span> 

	          		<br/><br/><br/><br/>
	          		<h4 style={{"margin-bottom": "0"}}>
	          			<FormattedMessage id='confirm_delivery_info'/>
	          		</h4>
	          		<span className="emphasized-blue-link" onClick={switchToPlanInfo}>
	          			<FormattedMessage id='change_delivery_details'/>
	          		</span>
	          		<br/><br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='delivery_country'/>
	          		</span>
	          		<span className="bold-text">
	          			{getDeliveryCountryName(deliveryCountry, language)}
	          		</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='delivery_contact_name'/>
	          		</span>
	          		<span className="bold-text">{deliveryContactName}</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='delivery_contact_phone'/>
	          		</span>
	          		<span className="bold-text">{deliveryContactPhone}</span> 
	          		<br/>
	          		<span className="input-label medium">
	          			<FormattedMessage id='delivery_address'/>
	          		</span>
	          		<span className="bold-text">{deliveryAddress}</span> 
	          		<br/>
	          		{ deliveryNotes !== "NONE" && 
		          		<span> 
			          		<span className="input-label medium">
			          			<FormattedMessage id='delivery_notes'/>
			          		</span>
			          		<span className="bold-text">{deliveryNotes}</span> 
		          		</span>
	          		}
	          		<br/><br/><br/>
	          		<h4>
	          			<FormattedMessage id='payment'/>
	          		</h4>

	          		<span>
	          			<span className="input-label medium">
	          			<FormattedMessage id='phone_service_cost'/>
	          			</span>
	          			${calculateCost(planSelected, promoCode).plan_cost}
	          			&nbsp;
	          			(${calculateCost(planSelected, promoCode).price_per_month}
	          			<FormattedMessage id='usd_per_month'/>
	          			&thinsp; for &thinsp;
	          			{calculateCost(planSelected, promoCode).num_months}
	          			<FormattedMessage id='month'/>)
	          		</span>
	          		
	          		<br/>
	          		<span>
	          			<span className="input-label medium">
	          				<FormattedMessage id='promo_code'/>
	          			</span>
	          			<input 
	          				id="promo_code" className="short-input" type="text"
	          				onBlur={applyPromoCode}
	          			/>
	          			<button className="inline-button">
	          				<FormattedMessage id='apply'/>
	          			</button>
	          		</span>
	          		<br/>
	          		{ promoCode !== "" && promoCode !== "NONE" && !hasPromoCodeError && 
	          			<span className="bold-text">
		          			<span className="input-label medium">
		          				<FormattedMessage id='discounted_price'/>
		          			</span>
		          			<span style={{color: "green"}}>
		          				${calculateCost(planSelected, promoCode).discounted_plan_cost}
		          				<FormattedMessage id='usd'/>
		          			</span>
		          			<br/>
		          			
		          		</span>
		          	}
		          	{ hasPromoCodeError &&  promoCodeError === "USER_REFERRAL" &&
	          			<span style={{color: "green"}}>
		          			{translatePromoCodeError(promoCodeError, language)}
		          		</span>
		          	}
		          	{ hasPromoCodeError &&  promoCodeError !== "USER_REFERRAL" &&
	          			<span style={{color: "red"}}>
		          			{translatePromoCodeError(promoCodeError, language)}
		          		</span>
		          	}
		          	<br/>
	          		<span>
	          			<span className="input-label medium">
	          				<FormattedMessage id='taxes'/>
	          			</span>
	          			${calculateCost(planSelected, promoCode).taxes}
	          			
	          		</span>
	          		<br/>
	          		<span className="bold-text">
	          			<span className="input-label medium">
	          				<FormattedMessage id='total_cost'/>
	          			</span>
	          			${calculateCost(planSelected, promoCode).total_cost}
	          			<FormattedMessage id='usd'/>
	          		</span>

		    		<br/><br/><br/>
		    		{ hasPaymentError &&
		    			<span className="error-message">
		    				<FormattedMessage id='has_payment_error'/> &nbsp;
		    				<a target="_blank" href="/contact">
		    					<FormattedMessage id='contact_us_short'/>
		    				</a>
		    			</span>
		    		}

		    		<AlipayButton isLoading={isLoading} onClick={initiateAlipay}/>
		    		<br/>
		    		<hr/>
		    		<br/>
		    		<img className="supported-cards" src={SupportedCards} alt="supported-cards"/>	


	        		{ hasPaymentError &&
			    			<span className="error-message">
			    				<FormattedMessage id='has_payment_error'/> &nbsp;
			    				<a target="_blank" href="/contact">
			    					<FormattedMessage id='contact_us_short'/>
			    				</a>
			    			</span>
			    		}

		        </div>
		      }
	    	</div>
        : 	
        <div>
        	<h4 className="emphasized-blue-link" onClick={() => setShowAuthModal(true)}>
	  			<FormattedMessage id="login_to_view"/>
	  		</h4>
        </div>
    	}
    </div>
	</div>
	: 
	<div className="ContentPage PlanSignup">
		<div className="FullPageSpinnerContainer">
			<FontAwesomeIcon icon="spinner" size="10x" className="spinning"/>
		</div>
	</div>
  );
};