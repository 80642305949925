import React from 'react';
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../libs/contextLib";

import PlanSignup from './PlanSignup';

import { planSignupTranslations } from '../../translations/planSignupTranslations';


import config from "../../config";



export default function PlanSignupContainer(){
	const { language } = useAppContext();

	return (
    	<IntlProvider locale={language} messages={planSignupTranslations[language]} >
	    	<div>
		    	<PlanSignup size="MediumUp"/> 
			</div>
		</IntlProvider>
    );
}