import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlipayLogo from '../images/alipay_logo.png'

import './AlipayButton.scss';

export default function AlipayButton({onClick, isLoading}){

	return(
		<div>
			<button className="AlipayButton" onClick={onClick}>
				<img className="alipay-logo" src={AlipayLogo} alt="alipay_logo"/>	
			</button>
			{ isLoading && 
				<FontAwesomeIcon style={{marginLeft: "15px"}}
				icon="spinner" size="2x" className="spinning"
				/>
			}
		</div>
	);

}