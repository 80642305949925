import React from 'react';

import FriendsSignup from './FriendsSignup';


import config from "../../config";



export default function FriendsSignupContainer(){

	return (
    	<div>
	    	<FriendsSignup size="MediumUp"/> 
		</div>
    );
}