import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { useLocation, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';


import { FormGroup, FormControl } from 'react-bootstrap';


import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from '../../libs/hooksLib';

import LoaderButton from '../../components/LoaderButton.js';

import './Auth.scss';

import { IntlProvider, FormattedMessage } from 'react-intl';
import { authTranslations } from '../../translations/authTranslations'

export default function ResetPassword(){
	const history = useHistory();

	const { language, userHasAuthenticated, setShowAuthModal } = useAppContext();

	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(true);
	const [message, setMessage] = useState('Make sure your passwords match!');

	const [fields, handleFieldChange] = useFormFields({
		username: "",
		code: "",
		password: "",
		confirm_password: ""
	});

	function validateForm(){
    	return fields.password.length > 0 && fields.confirm_password.length > 0;
  	}

  	async function handleSubmit(e){
  		e.preventDefault();
  		setIsLoading(true);


    	try {
      		await Auth.forgotPasswordSubmit(fields.username, fields.code, fields.password)
      		userHasAuthenticated(false);

      		setMessage('Success! You will be redirected shortly')
      		setIsSuccess(true);
      		setTimeout(() => {
      			history.push('/');
      			setIsLoading(false);
            setShowAuthModal(true);
      		}, 3000)
	    } catch(e) {
	    	await setMessage(e.message)
    		setIsSuccess(false);
      		setIsLoading(false);
	    }

	}

  const new_password_label = language === "en" ? "New Password" : "新密码"
  const confirm_password_label = language === "en" ? "Confirm Password" : "确认密码"

	return (
    <IntlProvider locale={language} messages={authTranslations[language]} >
  		<div className="ResetPassword">
  			<h3><FormattedMessage id="enterNewPassword"/></h3>
  			<form onSubmit={handleSubmit}>
  				<FormGroup controlId="username" bsSize="large">
                  <FormControl autoFocus type="email" placeholder={"Email"} onChange={handleFieldChange}/>
                </FormGroup>
                <FormGroup controlId="code" bsSize="large">
                  <FormControl autoFocus type="text" placeholder={"Code"} onChange={handleFieldChange}/>
                </FormGroup>
                <FormGroup controlId="password" bsSize="large">
                  <FormControl autoFocus type="password" placeholder={new_password_label} onChange={handleFieldChange}/>
                </FormGroup>
                <FormGroup controlId="confirm_password" bsSize="large">
                  <FormControl autoFocus type="password" placeholder={confirm_password_label} onChange={handleFieldChange}/>
                </FormGroup>
            		
            		<LoaderButton 
            			type="submit" label={<FormattedMessage id="setPassword"/>} style={`loader-button-secondary`}
            			disabled={!validateForm()} isLoading={isLoading}/>
        		</form>
        		<br></br>
  				{ isSuccess && <div className="emphasized-blue"> { message } </div> }
  				{!isSuccess && <div className="emphasized-orange"> { message }</div> }
  		</div>
    </IntlProvider>
	);
}