import React, {useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAppContext } from '../../libs/contextLib';

import './ChoosePlan.scss';

import SocialIcons from '../../components/SocialIcons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'

import Card from '../../components/Card';

import PlanDetailsENG from '../../documents/Plan_Details_ENG.pdf';
import PlanDetailsCHN from '../../documents/Plan_Details_CHN.pdf';

import {
	MONTHLY_COST_2M, MONTHLY_COST_6M, MONTHLY_COST_12M,
} from '../../FRONTEND_BILLING_VARIABLES';

import ReactGA from 'react-ga';

export default function ChoosePlan(props){
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	const history = useHistory();
	const class_name = props.size;

	const { language } = useAppContext();

	function goToService(path) {
		return () => {history.push(path)}
	}

	return (
		<div className="ContentPage ChoosePlan">
		    <div className={ class_name }>
		    	<span>
		    		<FormattedMessage id="starting"/>
		    	</span>
		    	<div style={{height: "1px", "marginTop": "-20px"}}/>
		    	<span className="big-plan-price">$45</span>
		    	<span className="big-plan-price-superscript"><sup>.99</sup></span>
		    	<span className="small-plan-price-caption">
		    		<sup>
		    			&nbsp; <FormattedMessage id="per_month"/>		    		
		    		</sup>
		    	</span>
		    	<div className="plan-highlights" style={{marginTop: "10px"}}>
		    		<p>
		    			<FormattedMessage id="unlimited_summary"/>
		    		</p>
		    		<p>
		    			<FormattedMessage id="wechat_summary"/>
		    		</p>
		    		<p>
		    			<FormattedMessage id="alipay_summary"/>
		    		</p>
		    		<p>
		    			<FormattedMessage id="sim_delivery_summary"/>
		    		</p>
		    		<p>
		    			<FormattedMessage id="no_ssn_summary"/>
		    		</p>
		    		<div style={{height:"13px"}}/>
			    	<a href={ language === "zh" ? PlanDetailsCHN : PlanDetailsENG } target="_blank">
			    		<FormattedMessage id="see_plan_details"/>
			    	</a>
		    	</div>
		    	
		    	<br/><br/><br/>
		    	<h3>
		    		<FormattedMessage id="select_duration"/>
		    	</h3>
		    	<div className="plan-cards">
		    		<div className="card-container">
			    		<Card 
			    			name={language === "zh"? "12个月" : "12 Months"} 
			    			description={language === "zh" ? "每月$"+MONTHLY_COST_12M+"美金" : "$"+MONTHLY_COST_12M+"/month"}
			    			redirect_url={'/choose/plan-signup?plan=12M'} 
			    			starred={true}
			    			button_text={language === "zh" ? "选择" : "Select"}
			    		/>
			    	</div>
			    	
			    	<div className="card-container">
			    		<Card 
			    			name={language === "zh"? "6个月" : "6 Months"}
			    			description={language === "zh" ? "每月$"+MONTHLY_COST_6M+"美金" : "$"+MONTHLY_COST_6M+"/month"}
			    			redirect_url={'/choose/plan-signup?plan=6M'}
			    			button_text={language === "zh" ? "选择" : "Select"}
			    		/>
			    	</div>
			    	<div className="card-container">
			    		<Card 
			    			name= {language === "zh"? "2个月" : "2 Months"}
			    			description={language === "zh" ? "每月$"+MONTHLY_COST_2M+"美金" : "$"+MONTHLY_COST_2M+"/month"}
			    			redirect_url={'/choose/plan-signup?plan=2M'}
			    			button_text={language === "zh" ? "选择" : "Select"}
			    		/>
			    	</div>
			    	
			    </div>
			    <div style={{height:"25px"}}/>
			    <span className="bold-text">
	  				<FormattedMessage id="or"/>
				</span>
				<div style={{height:"15px"}}/>
				<div onClick={()=>history.push('/contact')}>
					<span style={{fontSize:"20px"}} className="emphasized-blue-link">
						<FormattedMessage id="signup_with_wechat"/>
					</span>
					<SocialIcons/>
				</div>
				<br/><br/><br/>

		    	<h3>
		    		<FormattedMessage id="the_plan"/>
		    	</h3>
		    	<h5>
		    		<FormattedMessage id="the_plan_blurb"/>
		    	</h5>
		    	<ul>
		    		<li><FormattedMessage id="unlimited_calls"/></li>
		    		<li><FormattedMessage id="unlimited_data"/></li>
		    		<li><FormattedMessage id="official_partner"/></li>
		    		<li><FormattedMessage id="fastest_speed"/></li>
		    	</ul>

		    	<br/><br/>
		    	<h3>
		    		<FormattedMessage id="dedicated_service"/>
		    	</h3>
		    	<h5>
		    		<FormattedMessage id="dedicated_service_blurb"/>
		    	</h5>
		    	<ul>
		    		<li><FormattedMessage id="sim_delivery"/></li>
		    		<li><FormattedMessage id="chinese_service"/></li>
		    		<li><FormattedMessage id="multichannel_service"/></li>
		    		<li><FormattedMessage id="urgent_replacement"/></li>
		    		<li><FormattedMessage id="sms_reminders"/></li>
		    	</ul>

		    	<br/><br/>
		    	<h3>
		    		<FormattedMessage id="simple_process"/>
		    	</h3>
		    	<h5>
		    		<FormattedMessage id="simple_process_blurb"/>
		    	</h5>
		    	<ul>
		    		<li><FormattedMessage id="alipay"/></li>
		    		<li><FormattedMessage id="no_fees"/></li>
		    		
		    		<li><FormattedMessage id="secure_transactions"/></li>
		    		<li><FormattedMessage id="personal_information"/></li>
		    		<li><FormattedMessage id="no_ssn"/></li>
		    	</ul>

		    	<br/><br/><br/>

		    	


		    </div>
		</div>
    );
}