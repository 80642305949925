import React, { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";

import Routes from './Routes';
import { BreakpointProvider, Breakpoint} from 'react-socks';
import { useCookies } from 'react-cookie';

// helper imports
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import { useInterval } from './libs/hooksLib';
 

// Styling imports
import CustomNav from './components/CustomNav';
import CustomFooter from './components/CustomFooter';
import AuthModal from './containers/Auth/AuthModal';

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.1.1.0.min.scss";
import './App.scss';
import './styles/theme_styles.scss';


// Icons
import { library } from "@fortawesome/fontawesome-svg-core";
// general
import { 
  faCheck, faUser, faSpinner, faTimes, faAngleDoubleRight, 
  faCheckSquare, faCheckCircle, faExclamationTriangle, faTimesCircle,
  faCertificate, faMousePointer
} from '@fortawesome/free-solid-svg-icons';

function App() {
  // general use icons
  library.add(
    faCheck, faUser, faSpinner, faTimes, faAngleDoubleRight, 
    faCheckSquare, faCheckCircle, faExclamationTriangle, faTimesCircle,
    faCertificate, faMousePointer
  );

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [accessToken, updateAccessToken] = useState('');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [language, setLanguage] = useState('ENG');
  const [hasPaymentError, setHasPaymentError] = useState(false);

  // cookies
  const [cookies, setCookie,] = useCookies(['f1_lang']);
  const [cookiesEmail, setCookieEmail,] = useCookies(['user_email']);
  const [cookiesIdentityID, setCookieIdentityID,] = useCookies(['user_identity_id']);


  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const session = await Auth.currentSession();
      const user_info = await Auth.currentUserInfo();
      userHasAuthenticated(true);

      await setCookieEmail('user_email', session.idToken.payload.email, {path: "/"});
      await setCookieIdentityID('user_identity_id', user_info.id, {path: "/"});

    } catch(e) {
      if (e !== 'No current user'){
        alert(e);
      }
    }
    setIsAuthenticating(false);
    await getLanguage();
  }

  async function getLanguage() {
    console.log(navigator.language.substring(0, 2));;
    if (typeof cookies.f1_lang !== 'undefined'){
      await setLanguage(cookies.f1_lang);
    } else { // we only set lang to not chinese if user explicitly has english browser
      if (navigator.language.substring(0, 2) === "en") {
        await setLanguage("en")
        await setCookie('f1_lang', "en", {path: "/"});
      } else {
        await setLanguage("zh")
        await setCookie('f1_lang', "zh", {path: "/"});
      }
    }
  }


  return (
    !isAuthenticating && (
      <BreakpointProvider>
      <div className="App">
        <AppContext.Provider value = {{ 
          isAuthenticated, userHasAuthenticated, 
          isAuthenticating, setIsAuthenticating,
          showAuthModal, setShowAuthModal,
          language, setLanguage,
          hasPaymentError, setHasPaymentError
        }}>

          
          <Breakpoint medium up>
            <CustomNav size="MediumUp"/>
          </Breakpoint>
          <Breakpoint small down>
            <CustomNav size="SmallDown"/>
          </Breakpoint>
          <div className="AppContent">
            <AuthModal/>
            <Routes/>            
          </div>
          <CustomFooter/>
        </AppContext.Provider>
      </div>
      </BreakpointProvider>
    )

  );
}

export default App;
