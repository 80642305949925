import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom';

import { FormGroup, FormControl } from 'react-bootstrap';

import { useFormFields } from '../../libs/hooksLib';
import LoaderButton from '../../components/LoaderButton.js';

import './Auth.scss';

import { IntlProvider, FormattedMessage } from 'react-intl';
import { useAppContext } from "../../libs/contextLib";
import { authTranslations } from '../../translations/authTranslations'

export default function ForgotPassword(){
	const history = useHistory();

	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [message, setMessage] = useState(false);

	const [fields, handleFieldChange] = useFormFields({
		email: ""
	});

	function validateForm(){
    	return fields.email.length > 0;
  	}

  	async function handleSubmit(e){
  		e.preventDefault();
  		setIsLoading(true);
    	try {
      		await Auth.forgotPassword(fields.email);
      		setMessage(
      			'Success! Please check your email for a password-reset code.'
      		)
      		setIsSuccess(true);
      		setIsLoading(false);

      		await setTimeout(() => history.push('/auth/password-reset'), 3000)
	    } catch(e) {
	    	setMessage(e)
	    	setIsSuccess(false);
	    	setIsLoading(false);
	    }
	}

	const enter_email_label = language === "en" ? "Enter Your Email" : "请输入您的电子邮箱"



	return (
		<IntlProvider locale={language} messages={authTranslations[language]}>
			<div className="ForgotPassword">
				<h3><FormattedMessage id="passwordReset"/></h3>
				<form onSubmit={handleSubmit}>
					<FormGroup controlId="email" bsSize="large">
						<FormControl autoFocus type="email" placeholder={enter_email_label} onChange={handleFieldChange}/>
					</FormGroup>
	          		<LoaderButton 
	          			type="submit" label={<FormattedMessage id="passwordReset"/>} style={`loader-button-secondary`}
	          			disabled={!validateForm()} isLoading={isLoading}/>
	      		</form>
	      		<br></br>
	      		{ isSuccess && <div className="emphasized-blue"> { message } </div> }
	      		{!isSuccess && <div className="emphasized-orange"> { message }</div> }

	      		{ isSuccess && 
	      			<div className="forgot-password">
	      				If you are not redirected, reset your password
	      				<a href="/auth/password-reset" target="_blank">here</a>
	      			</div>
	      		}
			</div>
		</IntlProvider>
	);
}