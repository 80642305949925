import React, {useEffect} from 'react';
import { useAppContext } from "../libs/contextLib";

import WechatQRCode from '../images/wechat_qrcode.jpg';
import WhatsappQRCode from '../images/whatsapp_qrcode.jpg';

import MailIcon from '../images/mail_icon.png';
import WechatIcon from '../images/wechat_icon.jpg';
import WhatsappIcon from '../images/whatsapp_icon.png';

import { IntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { contactTranslations } from '../translations/contactTranslations';

import ReactGA from 'react-ga';

export default function Contact() {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	const { language } = useAppContext();
	return (
		<IntlProvider locale={language} messages={contactTranslations[language]} >
			<div className="ContentPage">
				<h3><FormattedMessage id="contact_us"/></h3>
				<p><FormattedMessage id="contact_blurb"/></p>
				<br/><br/>
				<h5>
					<img className="social-icon" src={MailIcon} alt="mail_icon"/>
					&nbsp; 
					Email
				</h5>
				<div className="indented-div">
					<p>f1plans@outlook.com</p>
				</div>
				<br/>

				<h5>
					<img className="social-icon" src={WechatIcon} alt="wechat_icon"/>
					&nbsp;
					<FormattedMessage id="wechat"/>
				</h5>
				<div className="indented-div">
					<img className="qr-code" src={WechatQRCode} alt="wechat_qr_code"/>
					<p><FormattedMessage id="add_by_phone"/>: 617 372 7309</p>
				</div>
				<br/>

				<h5>
					<img className="social-icon" src={WhatsappIcon} alt="whatsapp_icon"/>
					&nbsp;
					WhatsApp
				</h5>
				<div className="indented-div">
					<img className="qr-code" src={WhatsappQRCode} alt="whatsapp_qr_code"/>
					<p><FormattedMessage id="add_by_phone"/>: +1 617 372 7309</p>
				</div>
				
			</div>
		</IntlProvider>
	)
}