const authTranslations = {
	'en': {
		// auth modal
		login: "Login",
		signup: "Sign Up",
		dontHaveAccount: "Don't have an account? ",
		alreadyHaveAccount: "Already have an account? ",
		gotoSignup: "Sign up!",
		gotoLogin: "Login here!",
		forgotPassword: "Forgot password?",
		verifyEmail: "Verify Your Email!",
		verifyEmailComment: "Didn't get an email? Check your Spam Folder, or try re-sending!",
		resend: "Re-Send",
		skipForNow: "Skip For Now",


		// password reset etc
		passwordReset: "Reset Password",
		enterNewPassword: "Enter Your New Password",
		setPassword: "Set Password",

		// verify email
		emailBeingVerified: "We're verifying your email, please wait a few moments.",
		emailVerified: "Your email is verified！",
		emailCantBeVerified: "Sorry, we couldn't verify your email. Please reach out if you need support."
	},
	'zh': {
		// auth modal
		login: "登陆",
		signup: "注册账户",
		dontHaveAccount: "如未注册账户，",
		alreadyHaveAccount: "如已注册过账户，",
		gotoSignup: "请这里注册",
		gotoLogin: "请登录",
		forgotPassword: "找回密码",
		verifyEmail: "请验证您的邮箱!",
		verifyEmailComment: "如未收到验证邮件，请检查您的垃圾邮箱或重新发送验证邮件",
		resend: "重新发送验证邮件",
		skipForNow: "暂时忽略",

		// password reset etc
		passwordReset: "重置密码",
		enterNewPassword: "请输入新密码",
		setPassword: "设定新密码",

		// verify email
		emailBeingVerified: "正在验证您的邮箱，请稍后",
		emailVerified: "您已成功完成邮箱验证！",
		emailCantBeVerified: "未能验证您的电子邮箱。如需帮助请联系我们。"

	}
}

export { authTranslations };