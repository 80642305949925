import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../libs/contextLib";

import ChoosePlan from './ChoosePlan';

import { choosePlanTranslations } from '../../translations/choosePlanTranslations'

export default function ChoosePlanContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={choosePlanTranslations[language]} >
	    	<div>
		    	<Breakpoint medium up>
		    		<ChoosePlan size="MediumUp"/> 
				</Breakpoint>
				<Breakpoint small down>
					<ChoosePlan size="SmallDown"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}